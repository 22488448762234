import { Box, Skeleton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setSelectedRestaurants } from "../../store/slices/restaurantsList";

interface RestaurantIconContainerProps {
  name: string;
  picUrl: string;
}

export default function RestaurantIconContainer({
  picUrl,
  name,
}: RestaurantIconContainerProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const handleNavigate = () => {
    dispatch(setSelectedRestaurants(name));
    if (pathname === "/") {
      navigate("/restaurants/" + name);
    } else {
      navigate(name);
    }
  };
  const [loaded, setLoaded] = useState(false);
  const handleImageLoad = () => {
    setLoaded(true);
  };
  return (
    <Box
      sx={{ padding: "10px", position: "relative" }}
      onClick={handleNavigate}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            border: "3px solid white",
            background:
              "rgb(254,254,254) linear-gradient(90deg, rgba(254,254,254,1) 0%, rgba(226,226,226,1) 100%)",
            width: "160px",
            height: "160px",
            display: "flex",
            justifyContent: "center",
            borderRadius: "25px",
          }}
        >
          <Box
            sx={{
              width: "80%",
              mx: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            {!loaded && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="80%"
                animation="wave"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  borderRadius: "15px",
                }}
              />
            )}
            <Box
              component={"img"}
              src={picUrl}
              sx={{ width: "80%", opacity: loaded ? 1 : 0 }}
              onLoad={() => handleImageLoad()}
            />
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              margin: "auto",
              fontWeight: "600",
              fontSize: "12px",
              mt: "5px",
              textAlign: "center",
            }}
          >
            {name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
