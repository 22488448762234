import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Restaurant, { MenuItem } from "../../Interfaces/Restaurant";

export interface IRestaurantSlice {
  restaurants: Restaurant[];
  selectedRestaurant: Restaurant | undefined;
  menuItem: MenuItem | undefined;
}

const DEFAULT_STATE: IRestaurantSlice = {
  restaurants: [],
  selectedRestaurant: undefined,
  menuItem: undefined,
};

const restaurantsSlice = createSlice({
  name: "restaurantsList",
  initialState: DEFAULT_STATE,
  reducers: {
    setRestaurants(state, action: PayloadAction<Restaurant[]>) {
      state.restaurants = action.payload;
    },
    setSelectedRestaurants(state, action: PayloadAction<string>) {
      state.selectedRestaurant = state.restaurants.find(
        (restarurant) => restarurant.name === action.payload
      );
    },
    setSelectedMenuItem(state, action: PayloadAction<string>) {
      if (!state.selectedRestaurant) {
        state.menuItem = undefined;
        return;
      }

      const allMenuItems = Object.values(state.selectedRestaurant.menu).flat();

      // Find the menu item by name
      const foundItem = allMenuItems.find(
        (item) => item.name.toLowerCase() === action.payload.toLowerCase()
      );

      state.menuItem = foundItem || undefined; // Set the found item or null if not found
    },
  },
});

export const { setRestaurants, setSelectedRestaurants, setSelectedMenuItem } =
  restaurantsSlice.actions;

const restaurantReducer = restaurantsSlice.reducer;
export default restaurantReducer;
