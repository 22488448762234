import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGeneralSlice {
  mainDrawerOpen: boolean;
  registrationModalOpen: boolean;
  loadingModalOpen: boolean;
}

const DEFAULT_STATE: IGeneralSlice = {
  mainDrawerOpen: false,
  registrationModalOpen: false,
  loadingModalOpen: false,
};

const generalSlice = createSlice({
  name: "general",
  initialState: DEFAULT_STATE,
  reducers: {
    setMainDrawerOpen(state, action: PayloadAction<boolean>) {
      state.mainDrawerOpen = action.payload;
    },
    setRegistrationModalOpen(state, action: PayloadAction<boolean>) {
      state.registrationModalOpen = action.payload;
    },
    setLoadingModalOpen(state, action: PayloadAction<boolean>) {
      state.loadingModalOpen = action.payload;
    },
  },
});

export const {
  setMainDrawerOpen,
  setRegistrationModalOpen,
  setLoadingModalOpen,
} = generalSlice.actions;

const generalSliceReducer = generalSlice.reducer;
export default generalSliceReducer;
