import {
  Box,
  createTheme,
  CssBaseline,
  Drawer,
  ThemeProvider,
} from "@mui/material";
import logo from "./logo.svg";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import Restaurant from "./Pages/Restaurants/Restaurant";
import LoyaltyItems from "./Pages/LoyaltyItems/LoyaltyItems";
import Trending from "./Pages/Trending/Trending";
import { setMainDrawerOpen } from "./store/slices/general";
import { StoreState } from "./store/slices";
import LeftDrawer from "./Components/Drawer/LeftDrawer";
import RegistrationModal from "./Components/Modals/RegistrationModal";
import OneRestaurant from "./Pages/Restaurants/OneRestaurant";
import OneLoyaltyItem from "./Pages/LoyaltyItems/OneLoyaltyItem";
import SecondaryNav from "./Components/Navbar/SecondaryNav";
import NotFound from "./Pages/Error/NotFound";
import OneTrendingItem from "./Pages/Trending/OneTrendingItem";
import { Pages } from "./constants/enums";
import RestaurantSingleItem from "./Pages/Restaurants/RestaurantSingleItem";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./App.css";
import Review from "./Pages/Restaurants/Review";
import LoadingModal from "./Components/Modals/LoadingModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Compare from "./Pages/Restaurants/Compare";

function App() {
  const THEME = createTheme({
    typography: {
      fontFamily: `"Rubik","Roboto", "Helvetica", "Arial", sans-serif`,
    },
  });
  const dispatch = useDispatch();
  const mainDrawerOpen = useSelector(
    (state: StoreState) => state.general.mainDrawerOpen
  );
  const toggleDrawer = (newOpen: boolean) => () => {
    dispatch(setMainDrawerOpen(newOpen));
  };
  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      <ThemeProvider theme={THEME}>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          // transition: Bounce,
        />
        <Drawer open={mainDrawerOpen} onClose={toggleDrawer(false)}>
          <LeftDrawer />
        </Drawer>
        <RegistrationModal />
        <LoadingModal />
        <CssBaseline />
        {pathname.includes(Pages.oneLoyaltyItem) ||
        pathname.includes(Pages.oneTrendingItem) ||
        pathname.includes(Pages.review) ||
        pathname.includes(Pages.oneMenuItem) ? (
          <SecondaryNav />
        ) : (
          <Navbar />
        )}

        <TransitionGroup>
          <CSSTransition key={location.key} classNames="slide" timeout={300}>
            <Routes location={location}>
              <Route path="/" element={<Home />} />
              <Route
                path="restaurants/:restaurantName"
                Component={OneRestaurant}
              />
              <Route
                path="restaurants/:restaurantName/review"
                Component={Review}
              />
              <Route path="/restaurants" element={<Restaurant />}></Route>
              <Route path="/loyaltyItems" element={<LoyaltyItems />} />
              <Route
                path="/loyaltyItems/:loyaltyItemName"
                element={<OneLoyaltyItem />}
              />
              <Route
                path="restaurants/:restaurantName/restaurant-single-item"
                element={<RestaurantSingleItem />}
              />
              <Route
                path="restaurants/:restaurantName/restaurant-single-item/compare"
                element={<Compare />}
              />
              <Route path="/trending" element={<Trending />} />
              <Route path="/trending/:itemName" element={<OneTrendingItem />} />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </ThemeProvider>
    </>
  );
}

export default App;
