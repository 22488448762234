import { AppBar, Toolbar, Typography, Box, Button } from "@mui/material";
import logoPath from "../../Assets/Shahia.png";
import { BiMenuAltLeft } from "react-icons/bi";
import { TbShoppingBagPlus } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  setMainDrawerOpen,
  setRegistrationModalOpen,
} from "../../store/slices/general";
import { IoMdClose } from "react-icons/io";
import { Pages, Titles } from "../../constants/enums";
import { StoreState } from "../../store/slices";
export default function SecondaryNav() {
  const { pathname } = useLocation();
  const slug = pathname.split("/");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goBack = () => {
    const path = [...slug];
    // path.pop();
    // const newPath = path.join("/");
    // console.log(newPath);
    navigate(-1);
  };
  const selectedRestaurant = useSelector(
    (state: StoreState) => state.restaurants.selectedRestaurant
  );
  const selectedLoyaltyItem = useSelector(
    (state: StoreState) => state.loyaltyItems.selectedLoyaltyItem
  );
  const renderTitle = (pageSlug: string) => {
    if (pageSlug.includes(Pages.oneLoyaltyItem)) {
      console.log(selectedLoyaltyItem);
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontWeight={700} color={"black"} fontSize={18}>
            {pageSlug.split("/")[2].replaceAll("%20", " ")}
          </Typography>
        </Box>
      );
    } else if (
      pageSlug.includes(Pages.oneTrendingItem) ||
      pageSlug.includes(Pages.oneMenuItem)
    ) {
    } else if (pageSlug.includes(Pages.review)) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontWeight={700} color={"white"} fontSize={18}>
            Rate Station
          </Typography>
        </Box>
      );
    } else {
      return <Box component={"img"} src={logoPath} width={"30%"} />;
    }
  };

  const handlePress = () => {
    console.log("Pressed");
    if (slug[1]) {
      goBack();
    } else {
      dispatch(setMainDrawerOpen(true));
    }
  };
  const handleOpenRegistrationModal = () => {
    dispatch(setRegistrationModalOpen(true));
  };
  console.log({ pathname, c: pathname.includes(Pages.compare) });
  return (
    <Box sx={{ position: "relative", zIndex: 9999 }}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: pathname.includes(Pages.review)
            ? "#ef7724"
            : "transparent",
          padding: pathname.includes(Pages.review) ? "15px" : "20px",
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "center",
            minHeight: "32px",
            flexDirection: pathname.includes(Pages.compare)
              ? "row"
              : "row-reverse",
          }}
        >
          {/* Left Icon */}
          {!pathname.includes(Pages.compare) && (
            <Button
              variant="contained"
              aria-label="menu"
              sx={{
                // width: "35px",
                minWidth: "0px",
                backgroundColor: "transparent",
                boxShadow: "unset",
                p: "8px",
                borderRadius: "15px",
                position: "absolute",
                color: pathname.includes(Pages.review) ? "white" : "#ef7724",
                left: "0px",
                "&:hover": { bgcolor: "#ef7724", boxShadow: "none" },
              }}
              onClick={handlePress}
            >
              <IoMdArrowRoundBack size={"26px"} />
            </Button>
          )}
          {renderTitle(pathname)}
          {pathname.includes(Pages.oneMenuItem) &&
            (pathname.includes(Pages.compare) ? (
              <Button
                variant="contained"
                aria-label="menu"
                sx={{
                  // width: "35px",
                  minWidth: "0px",
                  backgroundColor: "#22a1c2",
                  boxShadow: "unset",
                  p: "8px",
                  borderRadius: "50%",
                  position: "absolute",
                  color: "black",
                  right: "0px",
                  "&:hover": { bgcolor: "#22a1c2", boxShadow: "none" },
                }}
                onClick={handlePress}
              >
                <IoMdClose size={"20px"} color="white" />
              </Button>
            ) : (
              <Button
                variant="contained"
                aria-label="menu"
                sx={{
                  // width: "35px",
                  minWidth: "0px",
                  backgroundColor: "#ef7724",
                  boxShadow: "unset",
                  p: "8px",
                  borderRadius: "15px",
                  position: "absolute",
                  // color: "black",
                  right: "0px",
                  "&:hover": { bgcolor: "#ef7724", boxShadow: "none" },
                }}
                onClick={handleOpenRegistrationModal}
              >
                <Box
                  sx={{
                    position: "absolute",
                    backgroundColor: "#D64545",
                    borderRadius: "50%",
                    height: "10px",
                    width: "10px",
                    top: "7px",
                    left: "7px",
                  }}
                ></Box>
                <TbShoppingBagPlus size={"26px"} />
              </Button>
            ))}
          {/* Right Icon */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
