import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Restaurant from "../../Interfaces/Restaurant";
import LoyaltyItem from "../../Interfaces/LoyaltyItem";

export interface ILoyaltyItemSlice {
  loyaltyItems: LoyaltyItem[];
  selectedLoyaltyItem: LoyaltyItem | null;
}

const DEFAULT_STATE: ILoyaltyItemSlice = {
  loyaltyItems: [],
  selectedLoyaltyItem: null,
};

const loyaltyItemsSlice = createSlice({
  name: "loyaltyItemsList",
  initialState: DEFAULT_STATE,
  reducers: {
    setLoyaltyItems(state, action: PayloadAction<LoyaltyItem[]>) {
      state.loyaltyItems = action.payload;
    },
    setSelectedLoyaltyItem(state, action: PayloadAction<LoyaltyItem>) {
      state.selectedLoyaltyItem = action.payload;
    },
  },
});

export const { setLoyaltyItems, setSelectedLoyaltyItem } =
  loyaltyItemsSlice.actions;

const loyaltyItemsReducer = loyaltyItemsSlice.reducer;
export default loyaltyItemsReducer;
