import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/slices";
import RestaurantIconContainer from "../../Components/Restaurants/RestaurantIconContainer";
import { Styles } from "../../Interfaces";
import { Outlet } from "react-router-dom";

export default function Restaurant() {
  const restaurants = useSelector(
    (state: StoreState) => state.restaurants.restaurants
  );
  return (
    <Grid container sx={{ mt: "120px", px: "20px" }}>
      <Box sx={styles.titleContainer}>
        <Typography fontFamily={"Rubik"} fontWeight={"600"} fontSize={18}>
          Our Restaurants
        </Typography>
      </Box>
      <Grid container sx={styles.containerGrid}>
        {restaurants.map((restaurant) => (
          <Grid item xs={4} key={restaurant.name} sx={styles.gridItem}>
            <RestaurantIconContainer
              name={restaurant.name}
              picUrl={restaurant.picUrl}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
const styles: Styles = {
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    color: "#22a1c2",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "500",
  },
};
