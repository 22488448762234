import { Box, Button, Divider, Link, Typography } from "@mui/material";
import { Styles } from "../../Interfaces";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/slices";
import { Link as RouterLink } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { setRegistrationModalOpen } from "../../store/slices/general";
export default function OneTrendingItem() {
  const selectedTrendingItem = useSelector(
    (state: StoreState) => state.trendingItems.selectedTrendingItem
  );
  const dispatch = useDispatch();
  const handleRedeem = () => {
    dispatch(setRegistrationModalOpen(true));
  };
  return (
    <Box sx={{ mt: "70px" }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box sx={{ position: "relative", width: "100%" }}>
            <Box
              component={"img"}
              src={selectedTrendingItem?.picUrl}
              sx={{
                height: "300px",
                objectFit: "cover",
                width: "100%",
                objectPosition: "center",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ p: "10px" }}>
        <Typography fontWeight={700}>{selectedTrendingItem?.title}</Typography>
        <Typography>{selectedTrendingItem?.description}</Typography>
      </Box>
    </Box>
  );
}
const styles: Styles = {
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: "20px",
  },
  link: {
    color: "#22a1c2",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "500",
  },
};
