import { Box, Skeleton } from "@mui/material";
import { useState } from "react";
import Carousel from "react-material-ui-carousel";
import i1 from "../../Assets/MainCaoursel/carousel1.webp";
import i2 from "../../Assets/MainCaoursel/carousel2.webp";
import i3 from "../../Assets/MainCaoursel/carousel3.webp";
import i4 from "../../Assets/MainCaoursel/carousel4.webp";
import i5 from "../../Assets/MainCaoursel/carousel5.webp";

var items = [i1, i2, i3, i4, i5];

export default function MainCarousel() {
  const [loaded, setLoaded] = useState(Array(items.length).fill(false));

  const handleImageLoad = (index: number) => {
    setLoaded((prevState) =>
      prevState.map((item, i) => (i === index ? true : item))
    );
  };

  return (
    <Carousel
      autoPlay={true}
      stopAutoPlayOnHover={true}
      animation="slide"
      interval={2000}
      navButtonsAlwaysInvisible={true}
      activeIndicatorIconButtonProps={{
        style: {
          color: "#ef7724",
        },
      }}
      duration={800}
      swipe={true}
    >
      {items.map((image, index) => (
        <Box key={index} sx={{ height: "175px", position: "relative" }}>
          {!loaded[index] && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              animation="wave"
              sx={{ position: "absolute", top: 0, left: 0 }}
            />
          )}
          <Box
            component={"img"}
            src={image}
            // loading="lazy"
            onLoad={() => handleImageLoad(index)}
            sx={{
              objectFit: "cover",
              objectPosition: "top",
              height: "100%",
              width: "100%",
              opacity: loaded[index] ? 1 : 0, // Hide until loaded
            }}
          />
        </Box>
      ))}
    </Carousel>
  );
}
