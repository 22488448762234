import {
  Box,
  Button,
  Chip,
  Input,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/slices";
import { useState } from "react";
import { setLoadingModalOpen } from "../../store/slices/general";
import { useLocation, useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";

export default function Review() {
  const selectedRestaurant = useSelector(
    (state: StoreState) => state.restaurants.selectedRestaurant
  );
  const [rating, setRating] = useState<number | null>(null);
  const [selectedChoices, setSelectedChoices] = useState<number[]>([]);
  const renderRating = () => {
    if (rating === null) {
      return "No rating given";
    }

    switch (rating) {
      case 0:
        return "Terrible experience overall";
      case 1:
        return "Not good, many issues";
      case 2:
        return "Average, some problems";
      case 3:
        return "Good, minor issues";
      case 4:
        return "Very good, enjoyed it";
      case 5:
        return "Excellent, highly recommended";
      default:
        return "Invalid rating";
    }
  };
  const handleClick = (e: number) => {
    if (selectedChoices.includes(e)) {
      setSelectedChoices(selectedChoices.filter((element) => element !== e));
      return;
    }
    setSelectedChoices([...selectedChoices, e]);
  };
  const [textValue, setTextValue] = useState("");
  const handleTextValueChange = (e: any) => {
    setTextValue(e.target.value);
  };
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const slug = pathname.split("/");
  const navigate = useNavigate();
  const goBack = () => {
    const path = [...slug];
    path.pop();
    const newPath = path.join("/");
    console.log(newPath);
    navigate(newPath);
  };
  const handleSubmitting = () => {
    if (!rating) {
      toast.error("Please Give a Rating First", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
      return;
    }
    dispatch(setLoadingModalOpen(true));
    setTimeout(() => {
      dispatch(setLoadingModalOpen(false));
      goBack();
    }, 3000);
  };
  return (
    <Box
      sx={{
        pt: "80px",
        overflowY: "clip",
        pb: "80px",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        px: "20px",
        gap: "20px",
      }}
    >
      <Typography fontSize={24} fontWeight={700} color={"#ef7724"}>
        {selectedRestaurant?.name}
      </Typography>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "20px",
          p: "15px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
        }}
      >
        <Box
          sx={{
            width: "40%",
            display: "flex",
            justifyContent: "center",
            my: "10px",
          }}
        >
          <Rating
            size="large"
            name="simple-controlled"
            value={rating ? rating : 0}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
            sx={{ width: "300px", textAlign: "center" }}
          />
        </Box>
        <Typography fontSize={14} fontWeight={700}>
          {renderRating()}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "20px",
          p: "15px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "flex-start",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
        }}
      >
        <Typography fontSize={14} fontWeight={700}>
          What Can Be Improved?
        </Typography>
        <Box
          sx={{ display: "flex", flexWrap: "wrap", gap: "10px", mt: "10px" }}
        >
          <Box
            sx={{
              border: selectedChoices.includes(0)
                ? "1px solid #ef7724"
                : "1px solid gray",
              borderRadius: "30px",
              px: "10px",
              py: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "all 200ms ease-in-out",
              backgroundColor: selectedChoices.includes(0)
                ? "#ef7724"
                : "transparent",
            }}
            onClick={() => handleClick(0)}
          >
            <Typography
              fontSize={13}
              fontWeight={400}
              sx={{
                transition: "all 200ms ease-in-out",
                color: selectedChoices.includes(0) ? "white" : "gray",
              }}
            >
              Food Quality
            </Typography>
          </Box>
          <Box
            sx={{
              border: selectedChoices.includes(1)
                ? "1px solid #ef7724"
                : "1px solid gray",
              borderRadius: "30px",
              px: "10px",
              py: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "all 200ms ease-in-out",
              backgroundColor: selectedChoices.includes(1)
                ? "#ef7724"
                : "transparent",
            }}
            onClick={() => handleClick(1)}
          >
            <Typography
              fontSize={13}
              fontWeight={400}
              sx={{
                transition: "all 200ms ease-in-out",
                color: selectedChoices.includes(1) ? "white" : "gray",
              }}
            >
              Portion Size
            </Typography>
          </Box>
          <Box
            sx={{
              border: selectedChoices.includes(2)
                ? "1px solid #ef7724"
                : "1px solid gray",
              borderRadius: "30px",
              px: "10px",
              py: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "all 200ms ease-in-out",
              backgroundColor: selectedChoices.includes(2)
                ? "#ef7724"
                : "transparent",
            }}
            onClick={() => handleClick(2)}
          >
            <Typography
              fontSize={13}
              fontWeight={400}
              sx={{
                transition: "all 200ms ease-in-out",
                color: selectedChoices.includes(2) ? "white" : "gray",
              }}
            >
              Delivery Experience
            </Typography>
          </Box>
          <Box
            sx={{
              border: selectedChoices.includes(3)
                ? "1px solid #ef7724"
                : "1px solid gray",
              borderRadius: "30px",
              px: "10px",
              py: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "all 200ms ease-in-out",
              backgroundColor: selectedChoices.includes(3)
                ? "#ef7724"
                : "transparent",
            }}
            onClick={() => handleClick(3)}
          >
            <Typography
              fontSize={13}
              fontWeight={400}
              sx={{
                transition: "all 200ms ease-in-out",
                color: selectedChoices.includes(3) ? "white" : "gray",
              }}
            >
              Packaging
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "20px",
          p: "15px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "flex-start",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
          gap: "10px",
        }}
      >
        <Typography fontSize={14} fontWeight={700}>
          Add Detailed Review
        </Typography>
        <Box
          sx={{
            width: "100%",
            borderRadius: "30px",
            backgroundColor: "#f2f5fa",
          }}
        >
          <TextField
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={textValue}
            onChange={handleTextValueChange}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "32px",
                  borderColor: "transparent !important",
                  backgroundColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.MuiInputBase-root": {
                  color: "black", // Ensure text color is black
                },
                "& textarea": {
                  color: "black", // Ensure textarea text color is black
                },
              },
            }}
          />
        </Box>
      </Box>
      <Button
        fullWidth
        sx={{
          fontSize: "20px",
          textTransform: "none",
          backgroundColor: "#ef7724",
          boxShadow: "none",
          borderRadius: "10px",
          "&:hover": {
            bgcolor: "#ef7724",
            boxShadow: "unset",
          },
        }}
        variant="contained"
        onClick={handleSubmitting}
      >
        Submit
      </Button>
    </Box>
  );
}
