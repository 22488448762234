import { Box, Rating, Skeleton, Typography } from "@mui/material";
import { useState } from "react";
import { GoHeartFill } from "react-icons/go";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedMenuItem } from "../../store/slices/restaurantsList";

interface Props {
  name: string;
  price: string;
  rating: number;
  description: string;
  picUrl: string;
}

export default function RestaurantMenuItem({
  name,
  price,
  rating,
  description,
  picUrl,
}: Props) {
  const [liked, setLiked] = useState(false);
  const navigate = useNavigate();

  const handleLikeToggle = (event: React.MouseEvent) => {
    event.stopPropagation();
    setLiked(!liked);
  };
  const dispatch = useDispatch();
  const handleNavigate = () => {
    navigate("restaurant-single-item", {
      state: { name, price, rating, description, picUrl },
    });
    dispatch(setSelectedMenuItem(name));
  };
  const [loaded, setLoaded] = useState(false);
  return (
    <Box
      sx={{
        backgroundColor: "white",
        mx: "15px",
        p: "10px",
        borderRadius: "15px",
        my: "10px",
        display: "flex",
        justifyContent: "space-between",
        cursor: "pointer",
        transition: "transform 0.2s",
        "&:hover": {
          transform: "scale(1.02)",
        },
        position: "relative",
      }}
      onClick={handleNavigate}
    >
      <Box sx={{ width: "58%", maxHeight: "160px", overflow: "hidden" }}>
        <Box>
          <Typography fontWeight={700}>{name}</Typography>
          <Typography fontSize={12} color={"#ef7724"}>
            {price}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Rating
            name="simple-controlled"
            value={rating}
            precision={0.1}
            readOnly
            size="medium"
            sx={{ flexShrink: 0, width: "30px" }}
            max={5}
          />
          <Typography fontSize={12}>({rating})</Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: "4",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "12px",
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ position: "relative", width: "40%" }}>
        <Box
          sx={{
            height: "160px",
            borderRadius: "15px",
            flexShrink: "0",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #ef7724",
            overflow: "hidden",
          }}
        >
          {!loaded && (
            <Skeleton
              variant="rectangular"
              height={"100%"}
              width="100%"
              animation="wave"
              sx={{ position: "absolute", borderRadius: "20px" }}
            />
          )}
          <Box
            component={"img"}
            src={picUrl}
            sx={{
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: "100%",
            }}
            onLoad={() => setLoaded(true)}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            backgroundColor: "white",
            borderRadius: "50%",
            padding: "5px",
            bottom: "-10px",
            right: "-10px",
            cursor: "pointer",
          }}
          onClick={handleLikeToggle}
        >
          <GoHeartFill size={26} style={{ color: liked ? "red" : "#f0f0f0" }} />
        </Box>
      </Box>
    </Box>
  );
}
