export enum Titles {
  restaurants = "Restaurants",
  loyaltyItems = "Loyalty Items",
  Trending = "Trending Now",
}
export enum Pages {
  RestaurantsPage = "/restaurants",
  LoyaltyItemsPage = "/loyaltyItems",
  TrendingItemsPage = "/trending",
  oneRestaurant = "/restaurants/",
  oneLoyaltyItem = "/loyaltyItems/",
  oneTrendingItem = "/trending/",
  review = "/review",
  oneMenuItem = "/restaurant-single-item",
  compare = "/compare",
}

export const dunkinMenu = [
  {
    name: "Apple Muffin",
    price: "120.0 EGP",
    rating: 4.3,
    description:
      "Juicy beef patty topped with smoky BBQ sauce, crispy bacon, and melted cheddar cheese, served with a side of fries.",
  },
];
