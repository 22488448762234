import { Box, Button, Link, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
export default function LeftDrawer() {
  return (
    <Box
      sx={{
        width: "250px",
        backgroundColor: "white",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", p: "10px" }}>
        <Typography
          fontWeight={700}
          color={"#ef7724"}
          fontSize={18}
          marginBottom={5}
        >
          Register Now
        </Typography>
        <Typography marginBottom={5} fontSize={14} fontWeight={400}>
          Join Shahia Family to Enjoy Our services
        </Typography>
        <Link
          marginBottom={30}
          component={RouterLink}
          to={"/termsAndConditions"}
          color={"#ef7724"}
        >
          Terms and Conditions
        </Link>
        <Button variant="contained" sx={{ backgroundColor: "#ef7724" }}>
          Sign Up
        </Button>
      </Box>
    </Box>
  );
}
