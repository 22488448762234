import generalSliceReducer, { IGeneralSlice } from "./general";
import loyaltyItemsReducer, { ILoyaltyItemSlice } from "./loyaltyItems";
import restaurantReducer, { IRestaurantSlice } from "./restaurantsList";
import trendingItemsReducer, { ITrendingItemsSlice } from "./trendingItems";

const slices = {
  restaurants: restaurantReducer,
  loyaltyItems: loyaltyItemsReducer,
  trendingItems: trendingItemsReducer,
  general: generalSliceReducer,
};
export interface StoreState {
  restaurants: IRestaurantSlice;
  loyaltyItems: ILoyaltyItemSlice;
  trendingItems: ITrendingItemsSlice;
  general: IGeneralSlice;
}

export default slices;
