import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import TrendingItem from "../../Interfaces/TrendingItem";

export interface ITrendingItemsSlice {
  trendingItems: TrendingItem[];
  selectedTrendingItem: TrendingItem | null;
}

const DEFAULT_STATE: ITrendingItemsSlice = {
  trendingItems: [],
  selectedTrendingItem: null,
};

const trendingItemsSlice = createSlice({
  name: "trendingItemsList",
  initialState: DEFAULT_STATE,
  reducers: {
    setTrendingItems(state, action: PayloadAction<TrendingItem[]>) {
      state.trendingItems = action.payload;
    },
    setSelectedTrendingItem(state, action: PayloadAction<TrendingItem>) {
      state.selectedTrendingItem = action.payload;
    },
  },
});

export const { setTrendingItems, setSelectedTrendingItem } =
  trendingItemsSlice.actions;

const trendingItemsReducer = trendingItemsSlice.reducer;
export default trendingItemsReducer;
