import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Drawer,
  Rating,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GoStopwatch } from "react-icons/go";
import { FaChevronRight, FaHeart, FaMinus, FaPlus } from "react-icons/fa";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { BiChevronUp } from "react-icons/bi";
import { lettuce, tomato } from "../../Assets/assets";
import { useDispatch, useSelector } from "react-redux";
import { setRegistrationModalOpen } from "../../store/slices/general";
import { StoreState } from "../../store/slices";
export default function RestaurantSingleItem() {
  const selectedMenuItem = useSelector(
    (state: StoreState) => state.restaurants.menuItem
  );
  const selectedRestaurant = useSelector(
    (state: StoreState) => state.restaurants.selectedRestaurant
  );
  const navigate = useNavigate();
  const [count, setCount] = useState(1);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleOpenRegistrationModal = () => {
    dispatch(setRegistrationModalOpen(true));
  };
  return (
    <Box
      sx={{
        pt: "70px",
        overflowY: "scroll",
        pb: "10rem",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        // px: "20px",
        // gap: "20px",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "200px",
          borderRadius: "15px",
          flexShrink: "0",
          position: "relative",
          // backgroundColor: "#f0f0f0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: "20px",
        }}
      >
        <Box
          component={"img"}
          src={`/${selectedRestaurant?.name.toLowerCase()}/${
            selectedMenuItem?.category
          }/${selectedMenuItem?.name}${selectedMenuItem?.picUrl}`}
          sx={{
            objectFit: "cover",
            objectPosition: "center",
            // width: "70%",
            // height: "70%",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          pb: "20px",
          px: "20px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#ef7724",
            display: "flex",
            px: "10px",
            py: "5px",
            borderRadius: "10px",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <GoStopwatch color="white" size={24} />
          <Typography fontWeight={600} fontSize={16} sx={{ color: "white" }}>
            20 min
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: "20px",
        }}
      >
        <Rating
          name="simple-controlled"
          value={selectedMenuItem?.rating}
          precision={0.1}
          readOnly
          size="medium"
          sx={{ flexShrink: 0, width: "30px" }}
          max={1}
        />
        <Typography sx={{ color: "#9c9c9c" }} fontSize={16}>
          ({selectedMenuItem?.rating})
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "30px",
          py: "15px",
          pb: "25px",
          px: "20px",
        }}
      >
        <Button
          sx={{
            p: 0,
            minWidth: "0px",
            px: "10px",
            py: "8px",
            backgroundColor: "#9c9c9c",
            borderRadius: "10px",
            boxShadow: "none",
            "&:hover": { bgcolor: "#9c9c9c", boxShadow: "none" },
          }}
          variant="contained"
          onClick={() => {
            if (count === 1) {
              return;
            }
            setCount((prevCount) => prevCount - 1);
          }}
        >
          <FaMinus size={24} color="white" />
        </Button>
        <Typography
          fontSize={20}
          fontWeight={800}
          sx={{ color: "#ef7724", minWidth: "30px", textAlign: "center" }}
        >
          {count}
        </Typography>
        <Button
          sx={{
            p: 0,
            minWidth: "0px",
            px: "10px",
            py: "8px",
            backgroundColor: "#9c9c9c",
            borderRadius: "10px",
            boxShadow: "none",
            "&:hover": { bgcolor: "#9c9c9c", boxShadow: "none" },
          }}
          onClick={() => setCount((prevCount) => prevCount + 1)}
          variant="contained"
        >
          <FaPlus size={24} color="white" />
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: "20px",
        }}
      >
        <Typography
          sx={{ color: "#ef7724", textAlign: "center" }}
          fontSize={22}
          fontWeight={700}
        >
          {selectedMenuItem?.name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: "10px",
          px: "20px",
        }}
      >
        <Typography sx={{ textAlign: "center" }} fontSize={14} fontWeight={400}>
          {selectedMenuItem?.description}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: "10px",
          px: "20px",
        }}
      >
        <Typography
          sx={{ textAlign: "center", color: "#f57c00" }}
          fontSize={18}
          fontWeight={400}
        >
          {selectedMenuItem?.price}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: "10px",
          px: "20px",
        }}
      >
        {/* <Button
          variant="contained"
          sx={{
            borderRadius: "8px",
            backgroundColor: "#ef7724",
            color: "white",
            boxShadow: "none",
            "&:hover": { bgcolor: "#ef7724", boxShadow: "none" },
            fontSize: "12px",
            py: "10px",
            px: "20px",
            textTransform: "none",
          }}
          onClick={() => navigate("compare")}
        >
          Compare
        </Button> */}
      </Box>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: "#ffab91",
            borderTopLeftRadius: "50px",
            borderTopRightRadius: "50px",
          },
        }}
      >
        <Box sx={{ p: "10px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{ color: "white", textTransform: "none" }}
              onClick={() => setOpen(false)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IoIosArrowDropdownCircle size={32} />
              </Box>
            </Button>
          </Box>
          <Typography
            sx={{ fontSize: "16px", color: "white", textAlign: "center" }}
          >
            Customize Item
          </Typography>
          <Box sx={{ maxHeight: "40vh", overflowY: "scroll", mt: "10px" }}>
            <Accordion
              sx={{
                backgroundColor: "#f2f5fa",
                my: "10px",
              }}
            >
              <AccordionSummary
                expandIcon={<BiChevronUp size={36} />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Ingredients</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      px: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={lettuce}
                      sx={{ height: "50px" }}
                    />
                    <Box>
                      <Typography fontSize={12}>Lettuce</Typography>
                      <Typography fontSize={12}>10.0</Typography>
                    </Box>
                    <Checkbox
                      // checked={checked}
                      // onChange={handleChange}
                      sx={{
                        borderRadius: "30px",
                        "&.Mui-checked": {
                          color: "#4d70b4",
                        },
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      px: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={tomato}
                      sx={{ height: "50px" }}
                    />
                    <Box>
                      <Typography fontSize={12}>Tomatoes</Typography>
                      <Typography fontSize={12}>15.0</Typography>
                    </Box>
                    <Checkbox
                      // checked={checked}
                      // onChange={handleChange}
                      sx={{
                        "&.Mui-checked": {
                          color: "#4d70b4",
                        },
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: "#f2f5fa",
                my: "10px",
              }}
            >
              <AccordionSummary
                expandIcon={<BiChevronUp size={36} />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Ingredients</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      px: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={lettuce}
                      sx={{ height: "50px" }}
                    />
                    <Box>
                      <Typography fontSize={12}>Lettuce</Typography>
                      <Typography fontSize={12}>10.0</Typography>
                    </Box>
                    <Checkbox
                      // checked={checked}
                      // onChange={handleChange}
                      sx={{
                        borderRadius: "30px",
                        "&.Mui-checked": {
                          color: "#4d70b4",
                        },
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      px: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={tomato}
                      sx={{ height: "50px" }}
                    />
                    <Box>
                      <Typography fontSize={12}>Tomatoes</Typography>
                      <Typography fontSize={12}>15.0</Typography>
                    </Box>
                    <Checkbox
                      // checked={checked}
                      // onChange={handleChange}
                      sx={{
                        "&.Mui-checked": {
                          color: "#4d70b4",
                        },
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: "#f2f5fa",
                my: "10px",
              }}
            >
              <AccordionSummary
                expandIcon={<BiChevronUp size={36} />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Ingredients</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      px: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={lettuce}
                      sx={{ height: "50px" }}
                    />
                    <Box>
                      <Typography fontSize={12}>Lettuce</Typography>
                      <Typography fontSize={12}>10.0</Typography>
                    </Box>
                    <Checkbox
                      // checked={checked}
                      // onChange={handleChange}
                      sx={{
                        borderRadius: "30px",
                        "&.Mui-checked": {
                          color: "#4d70b4",
                        },
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      px: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={tomato}
                      sx={{ height: "50px" }}
                    />
                    <Box>
                      <Typography fontSize={12}>Tomatoes</Typography>
                      <Typography fontSize={12}>15.0</Typography>
                    </Box>
                    <Checkbox
                      // checked={checked}
                      // onChange={handleChange}
                      sx={{
                        "&.Mui-checked": {
                          color: "#4d70b4",
                        },
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: "#f2f5fa",
                my: "10px",
              }}
            >
              <AccordionSummary
                expandIcon={<BiChevronUp size={36} />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Ingredients</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      px: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={lettuce}
                      sx={{ height: "50px" }}
                    />
                    <Box>
                      <Typography fontSize={12}>Lettuce</Typography>
                      <Typography fontSize={12}>10.0</Typography>
                    </Box>
                    <Checkbox
                      // checked={checked}
                      // onChange={handleChange}
                      sx={{
                        borderRadius: "30px",
                        "&.Mui-checked": {
                          color: "#4d70b4",
                        },
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      px: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={tomato}
                      sx={{ height: "50px" }}
                    />
                    <Box>
                      <Typography fontSize={12}>Tomatoes</Typography>
                      <Typography fontSize={12}>15.0</Typography>
                    </Box>
                    <Checkbox
                      // checked={checked}
                      // onChange={handleChange}
                      sx={{
                        "&.Mui-checked": {
                          color: "#4d70b4",
                        },
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: "#f2f5fa",
                my: "10px",
              }}
            >
              <AccordionSummary
                expandIcon={<BiChevronUp size={36} />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Ingredients</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      px: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={lettuce}
                      sx={{ height: "50px" }}
                    />
                    <Box>
                      <Typography fontSize={12}>Lettuce</Typography>
                      <Typography fontSize={12}>10.0</Typography>
                    </Box>
                    <Checkbox
                      // checked={checked}
                      // onChange={handleChange}
                      sx={{
                        borderRadius: "30px",
                        "&.Mui-checked": {
                          color: "#4d70b4",
                        },
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      px: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={tomato}
                      sx={{ height: "50px" }}
                    />
                    <Box>
                      <Typography fontSize={12}>Tomatoes</Typography>
                      <Typography fontSize={12}>15.0</Typography>
                    </Box>
                    <Checkbox
                      // checked={checked}
                      // onChange={handleChange}
                      sx={{
                        "&.Mui-checked": {
                          color: "#4d70b4",
                        },
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: "#f2f5fa",
                my: "10px",
              }}
            >
              <AccordionSummary
                expandIcon={<BiChevronUp size={36} />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Ingredients</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      px: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={lettuce}
                      sx={{ height: "50px" }}
                    />
                    <Box>
                      <Typography fontSize={12}>Lettuce</Typography>
                      <Typography fontSize={12}>10.0</Typography>
                    </Box>
                    <Checkbox
                      // checked={checked}
                      // onChange={handleChange}
                      sx={{
                        borderRadius: "30px",
                        "&.Mui-checked": {
                          color: "#4d70b4",
                        },
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      px: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={tomato}
                      sx={{ height: "50px" }}
                    />
                    <Box>
                      <Typography fontSize={12}>Tomatoes</Typography>
                      <Typography fontSize={12}>15.0</Typography>
                    </Box>
                    <Checkbox
                      // checked={checked}
                      // onChange={handleChange}
                      sx={{
                        "&.Mui-checked": {
                          color: "#4d70b4",
                        },
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Drawer>
      <Box sx={{ position: "absolute", bottom: "0px", width: "100%" }}>
        <Box
          sx={{
            bottom: "60px",
            backgroundColor: "#ffab91",
            width: "100%",
            padding: "10px",
            pb: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "40px 40px 0px 0px",
            position: "relative",
          }}
        >
          <Button
            sx={{ color: "white", textTransform: "none" }}
            onClick={() => setOpen(true)}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IoIosArrowDropupCircle size={32} />
              <Typography>Customize Item</Typography>
            </Box>
          </Button>
        </Box>
        <Box
          sx={{
            bottom: "0px",
            backgroundColor: "white ",
            width: "100%",
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "40px 40px 0px 0px",
            position: "absolute",
          }}
        >
          <Box onClick={handleOpenRegistrationModal}>
            <FaHeart color={"#9c9c9cF"} style={{ color: "#9c9c9c" }} />
          </Box>
          <Button
            sx={{
              fontSize: "20px",
              textTransform: "none",
              backgroundColor: "#ef7724",
              boxShadow: "none",
              borderRadius: "10px",
              position: "relative",
              paddingRight: "40px",
              width: "50%",
              py: "10px",
              "&:hover": {
                bgcolor: "#ef7724",
                boxShadow: "unset",
              },
            }}
            onClick={() => handleOpenRegistrationModal()}
            variant="contained"
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                {selectedMenuItem?.price}
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                Add To Cart
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                right: "10px",
              }}
            >
              <FaChevronRight
                size={16}
                style={{ position: "relative", left: "8px" }}
              />
              <FaChevronRight style={{ position: "relative", zIndex: 1 }} />
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
