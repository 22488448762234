import { Box, Divider, Grid, Rating, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/slices";
import RestaurantIconContainer from "../../Components/Restaurants/RestaurantIconContainer";
import { Styles } from "../../Interfaces";
import { Outlet } from "react-router-dom";
import { dunkin, MenuItemMashroom } from "../../Assets/assets";

export default function Compare() {
  const selectedMenuItem = useSelector(
    (state: StoreState) => state.restaurants.menuItem
  );
  const selectedRestaurant = useSelector(
    (state: StoreState) => state.restaurants.selectedRestaurant
  );
  const opsRestraurant = { name: "Cult", picUrl: dunkin };
  const opsMenuItem = {
    name: "Mushroom Swiss Burger",
    price: "110.0 EGP",
    rating: 4.3,
    description:
      "Grilled beef patty smothered with sautéed mushrooms and Swiss cheese, served on a toasted bun.",
    picUrl: MenuItemMashroom,
  };
  return (
    <Box
      sx={{
        mt: "60px",
        // px: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          mb: "20px",
          px: "20px",
        }}
      >
        <Typography
          textAlign={"center"}
          fontWeight={600}
          sx={{ width: "40%" }}
          fontSize={14}
        >
          {selectedMenuItem?.name}
        </Typography>
        <Box
          sx={{
            backgroundColor: "#22a1c2",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
          }}
        >
          <Typography
            sx={{ color: "white", fontSize: "18px", fontWeight: "600" }}
          >
            VS
          </Typography>
        </Box>
        <Typography
          textAlign={"center"}
          fontWeight={600}
          sx={{ width: "40%" }}
          fontSize={14}
        >
          {opsMenuItem.name}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          mb: "20px",
          px: "20px",
        }}
      >
        <Box sx={{ width: "40%" }}>
          <Box component={"img"} src={selectedMenuItem?.picUrl} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
          }}
        ></Box>
        <Box sx={{ width: "40%" }}>
          {" "}
          <Box component={"img"} src={opsMenuItem.picUrl} />
        </Box>
      </Box>
      <Divider sx={{ width: "100%", borderColor: "#22a1c2", mb: "20px" }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          mb: "20px",
          px: "20px",
        }}
      >
        <Box sx={{ width: "40%" }}>
          <Box component={"img"} src={selectedRestaurant?.picUrl} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
          }}
        ></Box>
        <Box sx={{ width: "40%" }}>
          {" "}
          <Box component={"img"} src={opsRestraurant?.picUrl} />
        </Box>
      </Box>
      <Divider sx={{ width: "100%", borderColor: "#22a1c2", mb: "20px" }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          mb: "20px",
          px: "20px",
        }}
      >
        <Box sx={{ width: "40%" }}>
          <Typography fontSize={16} fontWeight={700} textAlign={"center"}>
            {selectedMenuItem?.price}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
          }}
        ></Box>
        <Box sx={{ width: "40%" }}>
          <Typography fontSize={16} fontWeight={700} textAlign={"center"}>
            {opsMenuItem.price}
          </Typography>{" "}
        </Box>
      </Box>
      <Divider sx={{ width: "100%", borderColor: "#22a1c2", mb: "20px" }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          mb: "20px",
          px: "20px",
        }}
      >
        <Box
          sx={{
            width: "40%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Rating
            name="simple-controlled"
            value={selectedMenuItem?.rating}
            precision={0.1}
            readOnly
            size="medium"
            sx={{ flexShrink: 0, width: "70%" }}
            max={5}
          />
          <Typography fontSize={14} sx={{ color: "orange" }}>
            ({selectedMenuItem?.rating})
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
          }}
        ></Box>
        <Box
          sx={{
            width: "40%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Rating
            name="simple-controlled"
            value={opsMenuItem.rating}
            precision={0.1}
            readOnly
            size="medium"
            sx={{ flexShrink: 0, width: "70%" }}
            max={5}
          />
          <Typography fontSize={14} sx={{ color: "orange" }}>
            ({opsMenuItem.rating})
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ width: "100%", borderColor: "#22a1c2", mb: "20px" }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          mb: "20px",
          px: "20px",
        }}
      >
        <Box sx={{ width: "40%" }}>
          <Typography fontSize={14} fontWeight={400} textAlign={"center"}>
            {selectedMenuItem?.description}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
          }}
        ></Box>
        <Box sx={{ width: "40%" }}>
          <Typography fontSize={14} fontWeight={400} textAlign={"center"}>
            {opsMenuItem?.description}
          </Typography>{" "}
        </Box>
      </Box>
    </Box>
  );
}
const styles: Styles = {
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    color: "#22a1c2",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "500",
  },
};
