import { Box, Button, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { Styles } from "../../Interfaces";
import { IoCarSport } from "react-icons/io5";
import { BsPersonWalking } from "react-icons/bs";
import { RiEBike2Fill } from "react-icons/ri";
import { TiThSmall } from "react-icons/ti";
import RestaurantMenu from "../../Components/Restaurants/RestaurantMenu";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/slices";

export default function OneRestaurant() {
  const [selectedMode, setSelectedMode] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const handleClick = (index: number) => {
    setSelectedMode(index);
  };
  const handleChangeCategory = (name: string) => {
    setSelectedCategory(name);
  };
  const navigate = useNavigate();
  const handleRate = () => {
    console.log("clicked");
    navigate("review");
  };
  const selectedRestaurant = useSelector(
    (state: StoreState) => state.restaurants.selectedRestaurant
  );
  const allMenu = useMemo(() => {
    if (!selectedRestaurant) return []; // Return an empty array if no restaurant is selected
    const { menu } = selectedRestaurant;
    return Object.values(menu).flat(); // Combine all menu items from different categories
  }, [selectedRestaurant]);

  const filteredMenuItems = useMemo(() => {
    if (!selectedRestaurant) return [];
    return selectedCategory !== "all"
      ? selectedRestaurant.menu[selectedCategory] || []
      : allMenu;
  }, [selectedRestaurant, selectedCategory, allMenu]);
  return (
    <Box
      sx={{
        pt: "120px",
        overflowY: "clip",
        pb: "70px",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Box sx={styles.titleContainer}>
        <Typography fontFamily={"Rubik"} fontWeight={"600"} fontSize={18}>
          Please Select
        </Typography>
      </Box>
      {/* Revenue Centers Container */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
          gap: "15px",
        }}
      >
        <Button
          disableRipple={true}
          sx={{ display: "flex", flexDirection: "column" }}
          onClick={() => handleClick(0)}
        >
          <IoCarSport
            style={{ color: selectedMode === 0 ? "#ef7724" : "#9d9ea0" }}
            size={42}
          />
          <Typography
            fontSize={10}
            color={selectedMode === 0 ? "#ef7724" : "#9d9ea0"}
          >
            Drive Thru
          </Typography>
        </Button>
        <Button
          disableRipple={true}
          sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
          onClick={() => handleClick(1)}
        >
          <BsPersonWalking
            style={{ color: selectedMode === 1 ? "#ef7724" : "#9d9ea0" }}
            size={32}
          />
          <Typography
            fontSize={10}
            color={selectedMode === 1 ? "#ef7724" : "#9d9ea0"}
          >
            Pick Up
          </Typography>
        </Button>
        <Button
          disableRipple={true}
          sx={{ display: "flex", flexDirection: "column" }}
          onClick={() => handleClick(2)}
        >
          <RiEBike2Fill
            style={{ color: selectedMode === 2 ? "#ef7724" : "#9d9ea0" }}
            size={32}
          />
          <Typography
            fontSize={10}
            color={selectedMode === 2 ? "#ef7724" : "#9d9ea0"}
          >
            Delivery
          </Typography>
        </Button>
      </Box>
      {/* Category Filter Container */}
      <Box
        sx={{
          display: "flex",
          // justifyContent: "start",
          alignItems: "center",
          gap: "10px",
          p: "5px",
          bgcolor: "#e9f1f4",
          mb: 1,
          overflowX: "scroll",
          maxWidth: "100%",
          py: "10px",
          overflowY: "hidden",
        }}
      >
        <Button
          disableRipple={true}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            boxShadow: "none",
            backgroundColor: selectedCategory === "all" ? "#ef7724" : "white",
            border:
              "2px solid " +
              (selectedCategory === "all" ? "#ef7724" : "#9d9ea0"),
            borderRadius: "15px",
            "&:hover": {
              bgcolor: selectedCategory === "all" ? "#ef7724" : "white",
              boxShadow: "unset",
            },
            flexShrink: "0",
          }}
          onClick={() => handleChangeCategory("all")}
          variant="contained"
        >
          <TiThSmall
            style={{ color: selectedCategory === "all" ? "white" : "#9d9ea0" }}
            size={26}
          />
          <Typography
            fontSize={13}
            fontWeight={700}
            color={selectedCategory === "all" ? "white" : "#9d9ea0"}
          >
            All
          </Typography>
        </Button>
        {selectedRestaurant &&
          selectedRestaurant?.categories.map((category) => (
            <Button
              disableRipple={true}
              sx={{
                boxShadow: "none",
                textWrap: "nowrap",
                backgroundColor:
                  selectedCategory === category.name ? "#ef7724" : "white",
                border:
                  "2px solid " +
                  (selectedCategory === category.name ? "#ef7724" : "#9d9ea0"),
                borderRadius: "15px",
                color: selectedCategory === category.name ? "white" : "#9d9ea0",
                fontSize: "13px",
                flexShrink: "0",
                "&:hover": {
                  bgcolor:
                    selectedCategory === category.name ? "#ef7724" : "white",
                  boxShadow: "unset",
                },
              }}
              onClick={() => handleChangeCategory(category.name)}
              variant="contained"
              key={category.name}
            >
              {category.name}
            </Button>
          ))}
      </Box>
      <RestaurantMenu
        menuItems={filteredMenuItems}
        selectedRestaurantName={selectedRestaurant?.name || ""}
      />
      {/* Action Buttons */}
      <Box
        sx={{
          position: "fixed",
          bottom: "0px",
          backgroundColor: "#f5f5f5",
          width: "100%",
          padding: "20px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          height: "70px",
        }}
      >
        <Button
          sx={{
            fontSize: "20px",
            textTransform: "none",
            backgroundColor: "#ef7724",
            boxShadow: "none",
            borderRadius: "10px",
            width: "40%",
            "&:hover": {
              bgcolor: "#ef7724",
              boxShadow: "unset",
            },
          }}
          variant="contained"
          component="a"
          href="tel:555-123-4567"
        >
          Call Us
        </Button>
        <Button
          sx={{
            fontSize: "20px",
            textTransform: "none",
            backgroundColor: "#ef7724",
            boxShadow: "none",
            borderRadius: "10px",
            width: "40%",
            "&:hover": {
              bgcolor: "#ef7724",
              boxShadow: "unset",
            },
          }}
          variant="contained"
          onClick={handleRate}
        >
          Rate Us
        </Button>
      </Box>
    </Box>
  );
}
const styles: Styles = {
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: "20px",
  },
  link: {
    color: "#22a1c2",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "500",
  },
};
