import { Box, Modal, Fade, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/slices";

export default function LoadingModal() {
  const open = useSelector(
    (state: StoreState) => state.general.loadingModalOpen
  );
  //   const dispatch = useDispatch();

  const handleClose = () => {
    // dispatch(setRegistrationModalOpen(false));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "rgba(0,0,0,0.5)",
            p: 2,
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              //   backgroundColor: "red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{ color: "#22a1c2" }} />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
