import { Box, Grid, Link, SxProps, Typography } from "@mui/material";
import { Link as ReactRouterLink } from "react-router-dom";
import { Styles } from "../../Interfaces";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/slices";
import RestaurantIconContainer from "./RestaurantIconContainer";

export default function Restaurants() {
  const restaurants = useSelector(
    (state: StoreState) => state.restaurants.restaurants
  );

  return (
    <>
      <Box sx={styles.titleContainer}>
        <Typography fontFamily={"Rubik"} fontWeight={"600"} fontSize={18}>
          Our Restaurants
        </Typography>
        {/* <Link to={"/restaurants"} component={ReactRouterLink} sx={styles.link}>
          View all
          <ChevronRightIcon />
        </Link> */}
      </Box>
      <Grid container sx={styles.containerGrid}>
        {restaurants.slice(0, 9).map((restaurant) => (
          <Grid item xs={6} key={restaurant.name} sx={styles.gridItem}>
            <RestaurantIconContainer
              name={restaurant.name}
              picUrl={restaurant.picUrl}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
const styles: Styles = {
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    color: "#22a1c2",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "500",
  },
};
