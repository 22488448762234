import { useEffect } from "react";
import Restaurants from "../../Components/Restaurants/Restaurants";
import { Grid } from "@mui/material";
import { Styles } from "../../Interfaces";
import { useDispatch } from "react-redux";
import { setRestaurants } from "../../store/slices/restaurantsList";
import Restaurant from "../../Interfaces/Restaurant";
import LoyaltyProgram from "../../Components/Loyalty Program/LoyaltyProgram";
import LoyaltyItem from "../../Interfaces/LoyaltyItem";
import { setLoyaltyItems } from "../../store/slices/loyaltyItems";
import MainCarousel from "../../Components/Carousels/MainCarousel";
import TrendingNow from "../../Components/TrendingNow/Trending";
import TrendingItem from "../../Interfaces/TrendingItem";
import { setTrendingItems } from "../../store/slices/trendingItems";
import {
  tPepperoni,
  burger,
  espresso,
  food,
  tBbqBacon,
  tChicken,
  tChickenPizza,
  tFalafel,
  trendingPizza,
  tShawerma,
  dunkin,
  arbys,
  greenFalafel,
  gong_cha,
  greenFalafelNoBG,
  dunkinNoBG,
} from "../../Assets/assets";

const restaurants: Restaurant[] = [
  {
    name: "Dunkin",
    picUrl: dunkinNoBG,
    categories: [
      { name: "Bakery" },
      { name: "Coffee Bags" },
      { name: "Cold Beverages" },
      { name: "Donuts" },
      { name: "Hot Beverages" },
      { name: "Merchandise" },
      { name: "Sandwiches" },
    ],
    menu: {
      Bakery: [
        {
          name: "Apple Muffin",
          rating: 3.9,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "5.40 SAR",
          category: "Bakery",
        },
        {
          name: "Blueberry Muffin",
          rating: 4.05,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "25.83 SAR",
          category: "Bakery",
        },
        {
          name: "Cheese Croissant",
          rating: 4.55,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "7.32 SAR",
          category: "Bakery",
        },
        {
          name: "Choclate English Cake",
          rating: 3.74,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "13.67 SAR",
          category: "Bakery",
        },
        {
          name: "Choco Chop Cookies",
          rating: 3.9,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "19.01 SAR",
          category: "Bakery",
        },
        {
          name: "Chocolate Muffin",
          rating: 3.83,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "19.25 SAR",
          category: "Bakery",
        },
        {
          name: "Cream Cheese Croissant Sandwich",
          rating: 3.68,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.29 SAR",
          category: "Bakery",
        },
        {
          name: "Date Muffin",
          rating: 4.07,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.30 SAR",
          category: "Bakery",
        },
        {
          name: "Dates English Cake",
          rating: 4.23,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "14.40 SAR",
          category: "Bakery",
        },
        {
          name: "Double Chocolate Cookies",
          rating: 3.35,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "28.97 SAR",
          category: "Bakery",
        },
        {
          name: "Extreme Egg Croissent Sandwich",
          rating: 3.99,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "23.47 SAR",
          category: "Bakery",
        },
        {
          name: "Marble English Cake",
          rating: 4.42,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "21.89 SAR",
          category: "Bakery",
        },
        {
          name: "Plain Criossant",
          rating: 4.48,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "19.05 SAR",
          category: "Bakery",
        },
        {
          name: "Vanilla English Cake",
          rating: 3,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "19.57 SAR",
          category: "Bakery",
        },
        {
          name: "Vanilla Muffin",
          rating: 4.22,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "12.94 SAR",
          category: "Bakery",
        },
      ],
      "Coffee Bags": [
        {
          name: "Bold Breakfast Black Team",
          rating: 4.41,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "13.76 SAR",
          category: "Coffee Bags",
        },
        {
          name: "Bold Roast Espresso",
          rating: 3.43,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.87 SAR",
          category: "Coffee Bags",
        },
        {
          name: "Dark Roast Blend",
          rating: 4.13,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "10.70 SAR",
          category: "Coffee Bags",
        },
        {
          name: "Dunkin Decaf Blend",
          rating: 4.23,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "17.11 SAR",
          category: "Coffee Bags",
        },
        {
          name: "Light Roast Espresso",
          rating: 3.5,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "17.13 SAR",
          category: "Coffee Bags",
        },
        {
          name: "Original Coffee Blend",
          rating: 4.04,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.34 SAR",
          category: "Coffee Bags",
        },
        {
          name: "Signature Blend Espresso",
          rating: 4.95,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "11.12 SAR",
          category: "Coffee Bags",
        },
      ],
      "Cold Beverages": [
        {
          name: "Caramel Frappe",
          rating: 3.19,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "11.29 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Cold Brew Cappuccino",
          rating: 3.87,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "29.44 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Cold Brew Coffee",
          rating: 3.84,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "29.45 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Frozen Dunkaccino",
          rating: 4.28,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "6.95 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Ginger Cookie Iced Latte",
          rating: 3.92,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "28.06 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Hazelnut Iced Mocha",
          rating: 3.87,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "17.59 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Iced Americano",
          rating: 4.84,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "6.84 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Iced Caramel Machiato",
          rating: 4.57,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "18.07 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Iced Coffee",
          rating: 3.62,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.08 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Iced Latte",
          rating: 4.34,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "12.98 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Iced Mocha",
          rating: 4.2,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.09 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Iced Red Velvet Latte",
          rating: 3.6,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "14.07 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Iced Salted Caramel Latte",
          rating: 4.98,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "13.29 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Matcha Iced Latte",
          rating: 3.18,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "19.49 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Pistachio Frappe",
          rating: 3.08,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "14.04 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Shaken Lemonade",
          rating: 4.39,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "22.93 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Shaken Peach Iced Tea",
          rating: 3.3,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "5.57 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Spanish Iced Latte",
          rating: 3.49,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "27.34 SAR",
          category: "Cold Beverages",
        },
        {
          name: "Strawberry Frappe",
          rating: 4.78,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "29.61 SAR",
          category: "Cold Beverages",
        },
      ],
      Donuts: [
        {
          name: "25pcs Assorted Munchkins",
          rating: 4.53,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "7.67 SAR",
          category: "Donuts",
        },
        {
          name: "50pcs Assorted Munchkins",
          rating: 3.15,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "21.96 SAR",
          category: "Donuts",
        },
        {
          name: "6pcs Regular & 6pcs Fancy Donut",
          rating: 3.17,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "8.47 SAR",
          category: "Donuts",
        },
        {
          name: "Almond Kitkat Bar",
          rating: 4.65,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "23.71 SAR",
          category: "Donuts",
        },
        {
          name: "Bavarian Choco Caramel",
          rating: 4.42,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "13.28 SAR",
          category: "Donuts",
        },
        {
          name: "Bavarian Munchkin",
          rating: 4.62,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "14.56 SAR",
          category: "Donuts",
        },
        {
          name: "Bisco Cream",
          rating: 3.05,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "14.63 SAR",
          category: "Donuts",
        },
        {
          name: "Blue Sprinkle",
          rating: 4.54,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "10.89 SAR",
          category: "Donuts",
        },
        {
          name: "Boston Cream",
          rating: 4.79,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "16.65 SAR",
          category: "Donuts",
        },
        {
          name: "Choclate Glazed Munchkin",
          rating: 4.68,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "10.63 SAR",
          category: "Donuts",
        },
        {
          name: "Choco Butternut Munchkins",
          rating: 4.5,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "15.44 SAR",
          category: "Donuts",
        },
        {
          name: "Choco Frosted",
          rating: 4.6,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.18 SAR",
          category: "Donuts",
        },
        {
          name: "Choco Hazelnut Cheescake",
          rating: 3.43,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.71 SAR",
          category: "Donuts",
        },
        {
          name: "Coffee Roll",
          rating: 3.02,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.08 SAR",
          category: "Donuts",
        },
        {
          name: "Cookie Speculoos",
          rating: 4.34,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "22.73 SAR",
          category: "Donuts",
        },
        {
          name: "Cookies and Cream Munchkins",
          rating: 4.3,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "9.92 SAR",
          category: "Donuts",
        },
        {
          name: "Double Choclate",
          rating: 3.7,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "9.42 SAR",
          category: "Donuts",
        },
        {
          name: "Dozen Fancy Donuts",
          rating: 3.35,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "14.97 SAR",
          category: "Donuts",
        },
        {
          name: "Dozen Regular",
          rating: 3.93,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "13.68 SAR",
          category: "Donuts",
        },
        {
          name: "Glazed Donut",
          rating: 4.26,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "10.41 SAR",
          category: "Donuts",
        },
        {
          name: "Glazed Yeast Munchkins",
          rating: 3.7,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "23.92 SAR",
          category: "Donuts",
        },
        {
          name: "Half Dozen Fancy Donuts",
          rating: 4.07,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "18.90 SAR",
          category: "Donuts",
        },
        {
          name: "Half Dozen Regulart Donut",
          rating: 3.04,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "8.25 SAR",
          category: "Donuts",
        },
        {
          name: "Hazelnut Ring",
          rating: 4.43,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "27.86 SAR",
          category: "Donuts",
        },
        {
          name: "Long Jogn",
          rating: 3.38,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "10.30 SAR",
          category: "Donuts",
        },
        {
          name: "Long John Caramel Kitkat",
          rating: 4.31,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "14.65 SAR",
          category: "Donuts",
        },
        {
          name: "Mango Cheese Cake",
          rating: 3.11,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "10.58 SAR",
          category: "Donuts",
        },
        {
          name: "Matcha Donut",
          rating: 4.51,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "14.19 SAR",
          category: "Donuts",
        },
        {
          name: "Old Fashioned Donut",
          rating: 4.9,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "15.09 SAR",
          category: "Donuts",
        },
        {
          name: "Oreo Frosted Hazelnut",
          rating: 3.69,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "11.00 SAR",
          category: "Donuts",
        },
        {
          name: "Pistachio Chees Cake",
          rating: 3.43,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "6.02 SAR",
          category: "Donuts",
        },
        {
          name: "Pistachio Frosted",
          rating: 3,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.55 SAR",
          category: "Donuts",
        },
        {
          name: "Quarter Dozen Regular",
          rating: 3.42,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "26.32 SAR",
          category: "Donuts",
        },
        {
          name: "Quarter Fancy Donuts",
          rating: 3.38,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "22.74 SAR",
          category: "Donuts",
        },
        {
          name: "Salted Caramel Donut",
          rating: 3.87,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "9.00 SAR",
          category: "Donuts",
        },
        {
          name: "White Choco Matcha",
          rating: 4.28,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "8.89 SAR",
          category: "Donuts",
        },
      ],
      "Hot Beverages": [
        {
          name: "Americano",
          rating: 4.54,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.02 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Bold Breakfast Black Tea",
          rating: 4.64,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.48 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Cappuccino",
          rating: 4.05,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "21.42 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Dark Roast Coffee",
          rating: 4.72,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "8.19 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Espresso",
          rating: 4.91,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "8.64 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Ginger Cookie Latte",
          rating: 4.2,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.78 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Hazelnut Mocha",
          rating: 3.18,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "11.80 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Hot Choclate",
          rating: 4.35,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "5.82 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Hot Red Velvet Mocha",
          rating: 4.4,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "26.63 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Hot Red Velvet",
          rating: 4.56,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "6.09 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Karak Chai",
          rating: 4.54,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "8.16 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Latte",
          rating: 3.16,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "8.07 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Matcha Latte",
          rating: 4.47,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "21.26 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Mocha",
          rating: 4.5,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "22.01 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Original Dunkin Coffee",
          rating: 4.96,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "11.02 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Salted Caramel Hot Latte",
          rating: 3.41,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "13.29 SAR",
          category: "Hot Beverages",
        },
        {
          name: "Spanish Hot Latte",
          rating: 4.09,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "29.30 SAR",
          category: "Hot Beverages",
        },
      ],
      Merchandise: [
        {
          name: "Chocola Tumbler Black",
          rating: 4.01,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "15.05 SAR",
          category: "Merchandise",
        },
        {
          name: "Dunkin Mug White",
          rating: 3.07,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "23.71 SAR",
          category: "Merchandise",
        },
        {
          name: "Stainless Black",
          rating: 3.43,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "25.69 SAR",
          category: "Merchandise",
        },
        {
          name: "Stainless White",
          rating: 4.82,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "27.47 SAR",
          category: "Merchandise",
        },
      ],
      Sandwiches: [
        {
          name: "Bagel Cinnamon Double Cheese",
          rating: 4.54,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "26.05 SAR",
          category: "Sandwiches",
        },
        {
          name: "Bagel Cream Cheese Cinnamon",
          rating: 4.08,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.13 SAR",
          category: "Sandwiches",
        },
        {
          name: "Bagel Cream Cheese Oats",
          rating: 4.02,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "8.97 SAR",
          category: "Sandwiches",
        },
        {
          name: "Bagel Cream Cheese Plain",
          rating: 4.86,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "21.18 SAR",
          category: "Sandwiches",
        },
        {
          name: "Bagel Egg & Cheese Cinnamon",
          rating: 4.37,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "9.18 SAR",
          category: "Sandwiches",
        },
        {
          name: "Bagel Egg & Cheese Oats",
          rating: 4.56,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.14 SAR",
          category: "Sandwiches",
        },
        {
          name: "Bagel Egg & Cheese Plain",
          rating: 3.13,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "26.19 SAR",
          category: "Sandwiches",
        },
        {
          name: "Bagel Oats Double Cheese",
          rating: 4.29,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "27.69 SAR",
          category: "Sandwiches",
        },
        {
          name: "Bagel Plain Double Cheese",
          rating: 4.21,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "22.95 SAR",
          category: "Sandwiches",
        },
        {
          name: "Big & Toasty Chicken",
          rating: 4.65,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.64 SAR",
          category: "Sandwiches",
        },
        {
          name: "Big N` Toasty Cheese & Egg",
          rating: 4.05,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.69 SAR",
          category: "Sandwiches",
        },
        {
          name: "Choco Hazelnut Toast",
          rating: 4.47,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "13.07 SAR",
          category: "Sandwiches",
        },
        {
          name: "Extreme Egg Sandwich Plain Bagel",
          rating: 4.25,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "19.59 SAR",
          category: "Sandwiches",
        },
        {
          name: "Extreme Egg Sandwich Toasty",
          rating: 3.18,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "17.44 SAR",
          category: "Sandwiches",
        },
        {
          name: "Labneh Honey Toast",
          rating: 4.14,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "19.91 SAR",
          category: "Sandwiches",
        },
        {
          name: "Labneh Pesto Toast",
          rating: 3,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "26.63 SAR",
          category: "Sandwiches",
        },
      ],
    },
  },
  {
    name: "Arbys",
    picUrl: arbys,
    categories: [
      { name: "Brisket" },
      { name: "Roast Beef" },
      { name: "Chicken" },
      { name: "Turkey" },
      { name: "Sides" },
      { name: "Kids" },
      { name: "Deserts and Shakes" },
      { name: "Beverages" },
    ],
    menu: {
      Brisket: [
        {
          name: "Smoked Beef Brisket",
          rating: 3.35,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "6.87 SAR",
          category: "Brisket",
        },
        {
          name: "Smokehouse Brisket Philly",
          rating: 4.06,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "25.60 SAR",
          category: "Brisket",
        },
      ],
      "Roast Beef": [
        {
          name: "Deluxe Beef & Cheddar",
          rating: 4.19,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.18 SAR",
          category: "Roast Beef",
        },
        {
          name: "Roast Beef Philly",
          rating: 4.44,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "19.04 SAR",
          category: "Roast Beef",
        },
      ],
      Chicken: [
        {
          name: "Buffalo Chicken",
          rating: 3.65,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.36 SAR",
          category: "Chicken",
        },
        {
          name: "Crispy Chciken & Swiss",
          rating: 4.32,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "6.78 SAR",
          category: "Chicken",
        },
        {
          name: "Smokey BBQ Chicken",
          rating: 4.53,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "9.55 SAR",
          category: "Chicken",
        },
      ],
      Turkey: [
        {
          name: "Triple Chees Turkey Melt",
          rating: 4.91,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.30 SAR",
          category: "Turkey",
        },
      ],
      Sides: [
        {
          name: "Chicken Tenders",
          rating: 4.99,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "18.36 SAR",
          category: "Sides",
        },
        {
          name: "Crinkle Fries",
          rating: 3.35,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "19.64 SAR",
          category: "Sides",
        },
        {
          name: "Curly Fries",
          rating: 4.99,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.29 SAR",
          category: "Sides",
        },
        {
          name: "Jalapeno Bites",
          rating: 4.53,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "9.22 SAR",
          category: "Sides",
        },
        {
          name: "Loaded Fries",
          rating: 3.71,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "28.73 SAR",
          category: "Sides",
        },
        {
          name: "Mozz Sticks",
          rating: 4.74,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.46 SAR",
          category: "Sides",
        },
        {
          name: "Onion Rings",
          rating: 3.66,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.04 SAR",
          category: "Sides",
        },
      ],
      Kids: [
        {
          name: "Chicken & Cheese Slider",
          rating: 4.15,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "13.17 SAR",
          category: "Kids",
        },
        {
          name: "Chicken Tenders",
          rating: 4.79,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "17.15 SAR",
          category: "Kids",
        },
        {
          name: "Turkey & Cheese Slider",
          rating: 3.4,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "16.49 SAR",
          category: "Kids",
        },
      ],
      "Deserts and Shakes": [
        {
          name: "Apple Turnover With Icing",
          rating: 3.84,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "5.84 SAR",
          category: "Deserts and Shakes",
        },
        {
          name: "Chocolate Shake",
          rating: 3.57,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "15.10 SAR",
          category: "Deserts and Shakes",
        },
        {
          name: "Jamocha Shake",
          rating: 3.84,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "12.39 SAR",
          category: "Deserts and Shakes",
        },
        {
          name: "Vanilla Shake",
          rating: 3.36,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "8.72 SAR",
          category: "Deserts and Shakes",
        },
      ],
      Beverages: [
        {
          name: "Coca Cola Zero Sugar",
          rating: 3.77,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "27.86 SAR",
          category: "Beverages",
        },
        {
          name: "Coca Cola",
          rating: 4.92,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "5.46 SAR",
          category: "Beverages",
        },
        {
          name: "Fanta",
          rating: 3.23,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.93 SAR",
          category: "Beverages",
        },
        {
          name: "Sprite",
          rating: 3.88,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "12.38 SAR",
          category: "Beverages",
        },
      ],
    },
  },
  {
    name: "Green Falafel",
    picUrl: greenFalafelNoBG,
    categories: [
      { name: "Pie" },
      { name: "Sandwiches" },
      { name: "Manakeesh" },
      { name: "All Day Breakfast" },
      { name: "Falafel On The Go" },
      { name: "Appetizers" },
      { name: "Salads" },
      { name: "Specialties" },
      { name: "Drinks" },
    ],
    menu: {
      Pie: [
        {
          name: "Cheese Pie",
          rating: 4.58,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "25.44 SAR",
          category: "Pie",
        },
        {
          name: "Falafel Pie",
          rating: 4.41,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "23.62 SAR",
          category: "Pie",
        },
        {
          name: "Labaneh Honey Pie",
          rating: 3.04,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "26.80 SAR",
          category: "Pie",
        },
        {
          name: "Labaneh Pie",
          rating: 3.66,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "27.51 SAR",
          category: "Pie",
        },
        {
          name: "Mix Labaneh Zaatar Pie",
          rating: 4.73,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.82 SAR",
          category: "Pie",
        },
        {
          name: "Mix Zaatar Cheese Pie",
          rating: 4.3,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "18.72 SAR",
          category: "Pie",
        },
        {
          name: "Mouhamara Pie",
          rating: 4.83,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "8.47 SAR",
          category: "Pie",
        },
        {
          name: "Spinach Pie",
          rating: 3.54,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "17.48 SAR",
          category: "Pie",
        },
      ],
      Sandwiches: [
        {
          name: "Falafel Eggplant Sandwich",
          rating: 3.18,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "5.96 SAR",
          category: "Sandwiches",
        },
        {
          name: "French Fries Sandwich",
          rating: 4.45,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "27.16 SAR",
          category: "Sandwiches",
        },
        {
          name: "Grill Halloumi Sandwich",
          rating: 4.39,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "5.11 SAR",
          category: "Sandwiches",
        },
        {
          name: "Grilled Falafel Sandwich",
          rating: 4.72,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "28.64 SAR",
          category: "Sandwiches",
        },
        {
          name: "Mix Sand Falafel",
          rating: 4.57,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "27.71 SAR",
          category: "Sandwiches",
        },
        {
          name: "Traditional Falafel",
          rating: 4.9,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "15.59 SAR",
          category: "Sandwiches",
        },
        {
          name: "Tuna Sandwich",
          rating: 3.1,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "10.18 SAR",
          category: "Sandwiches",
        },
        {
          name: "Veggie Sandwich",
          rating: 3.88,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "26.11 SAR",
          category: "Sandwiches",
        },
      ],
      Manakeesh: [
        {
          name: "Labneh Honey",
          rating: 4.21,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "10.01 SAR",
          category: "Manakeesh",
        },
        {
          name: "Labneh",
          rating: 4.71,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "17.32 SAR",
          category: "Manakeesh",
        },
        {
          name: "Mankoucha Cheese",
          rating: 3.7,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.41 SAR",
          category: "Manakeesh",
        },
        {
          name: "Manqusha Thyme",
          rating: 3.79,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "14.00 SAR",
          category: "Manakeesh",
        },
        {
          name: "Mix Zaatar Cheese",
          rating: 4.39,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "28.63 SAR",
          category: "Manakeesh",
        },
        {
          name: "Mouhamara",
          rating: 4.87,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "11.13 SAR",
          category: "Manakeesh",
        },
      ],
      "All Day Breakfast": [
        {
          name: "Crushed Fool",
          rating: 4.84,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "25.99 SAR",
          category: "All Day Breakfast",
        },
        {
          name: "Fattet Eggplant",
          rating: 4.53,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "7.85 SAR",
          category: "All Day Breakfast",
        },
        {
          name: "Fattet Falafel",
          rating: 3.19,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "7.80 SAR",
          category: "All Day Breakfast",
        },
        {
          name: "Fattet Humus",
          rating: 4.66,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "16.07 SAR",
          category: "All Day Breakfast",
        },
        {
          name: "Hummus Beetroot",
          rating: 3.86,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "17.16 SAR",
          category: "All Day Breakfast",
        },
        {
          name: "Hummus Green",
          rating: 3.46,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "14.37 SAR",
          category: "All Day Breakfast",
        },
        {
          name: "Hummus",
          rating: 3.49,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "16.53 SAR",
          category: "All Day Breakfast",
        },
        {
          name: "Moutabal Beetroot",
          rating: 3.48,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "29.44 SAR",
          category: "All Day Breakfast",
        },
        {
          name: "Moutabal Green",
          rating: 4.26,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "27.91 SAR",
          category: "All Day Breakfast",
        },
        {
          name: "Moutabal",
          rating: 3.95,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "7.21 SAR",
          category: "All Day Breakfast",
        },
      ],
      "Falafel On The Go": [
        {
          name: "Cheezy Falafel",
          rating: 4.02,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "16.93 SAR",
          category: "Falafel On The Go",
        },
        {
          name: "Fried Falafel Chilli",
          rating: 3.89,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "13.58 SAR",
          category: "Falafel On The Go",
        },
        {
          name: "Fried Falafel",
          rating: 4.18,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "18.33 SAR",
          category: "Falafel On The Go",
        },
        {
          name: "Grilled Falafel Chilli",
          rating: 3.96,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "28.01 SAR",
          category: "Falafel On The Go",
        },
        {
          name: "Grilled Falafel",
          rating: 3.22,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "25.73 SAR",
          category: "Falafel On The Go",
        },
      ],
      Appetizers: [
        {
          name: "Falafel Chees Spring Roll (6 Pcs)",
          rating: 4.85,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "8.77 SAR",
          category: "Appetizers",
        },
        {
          name: "Falafel Fingers (6 Pcs)",
          rating: 3.53,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "18.72 SAR",
          category: "Appetizers",
        },
        {
          name: "Falafel Popcorn (12 Pcs)",
          rating: 4.58,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "29.24 SAR",
          category: "Appetizers",
        },
        {
          name: "Falafel Spring Roll (6 Pcs)",
          rating: 3.33,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "16.17 SAR",
          category: "Appetizers",
        },
        {
          name: "French Fries Plate",
          rating: 4.76,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "13.30 SAR",
          category: "Appetizers",
        },
        {
          name: "Potato Harra",
          rating: 4.13,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "28.49 SAR",
          category: "Appetizers",
        },
        {
          name: "Potato Kebbeh",
          rating: 4.84,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "19.91 SAR",
          category: "Appetizers",
        },
        {
          name: "Rice Kebbeh",
          rating: 4.22,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.23 SAR",
          category: "Appetizers",
        },
        {
          name: "Spicy Falafel Spring Roll 6 Pcs",
          rating: 4.4,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "9.35 SAR",
          category: "Appetizers",
        },
      ],
      Salads: [
        {
          name: "Beetroot Rocca Salad",
          rating: 4.09,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "8.48 SAR",
          category: "Salads",
        },
        {
          name: "Fattouch",
          rating: 4.4,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "15.38 SAR",
          category: "Salads",
        },
        {
          name: "Green",
          rating: 3.32,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.79 SAR",
          category: "Salads",
        },
        {
          name: "Rocca Salad",
          rating: 3.09,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "26.32 SAR",
          category: "Salads",
        },
        {
          name: "Tabouleh",
          rating: 3.24,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "26.63 SAR",
          category: "Salads",
        },
        {
          name: "Tuna Salad",
          rating: 3.01,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "5.68 SAR",
          category: "Salads",
        },
        {
          name: "Vegetable Plate",
          rating: 4.64,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "26.87 SAR",
          category: "Salads",
        },
      ],
      Specialties: [
        {
          name: "Appetizer Box",
          rating: 3.19,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "15.25 SAR",
          category: "Specialties",
        },
        {
          name: "Box El Dayya",
          rating: 4.5,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "22.71 SAR",
          category: "Specialties",
        },
        {
          name: "Green Falafel Box",
          rating: 4,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "25.02 SAR",
          category: "Specialties",
        },
        {
          name: "Mini Veggie Sandwich Box",
          rating: 3.91,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "12.48 SAR",
          category: "Specialties",
        },
      ],
      Drinks: [
        {
          name: "Coca Cola Regular",
          rating: 4.51,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "9.44 SAR",
          category: "Drinks",
        },
        {
          name: "Coca Cola Zero",
          rating: 4.94,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.46 SAR",
          category: "Drinks",
        },
        {
          name: "Fanta",
          rating: 4.56,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "11.16 SAR",
          category: "Drinks",
        },
        {
          name: "Sprite",
          rating: 3.73,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "13.78 SAR",
          category: "Drinks",
        },
      ],
    },
  },
  {
    name: "Gong Cha",
    picUrl: gong_cha,
    categories: [
      { name: "All Day Tea" },
      { name: "Fruit Tea" },
      { name: "Signature Tea" },
    ],
    menu: {
      "All Day Tea": [
        {
          name: "Black Tea Hot",
          rating: 3.84,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "17.56 SAR",
          category: "All Day Tea",
        },
        {
          name: "Black Tea Iced",
          rating: 4.47,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "10.98 SAR",
          category: "All Day Tea",
        },
        {
          name: "Black Tea with Milk Foam Hot",
          rating: 3.62,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "9.81 SAR",
          category: "All Day Tea",
        },
        {
          name: "Black Tea with Milk Foam Iced",
          rating: 3.81,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.43 SAR",
          category: "All Day Tea",
        },
        {
          name: "Green Tea Hot",
          rating: 4.29,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "18.44 SAR",
          category: "All Day Tea",
        },
        {
          name: "Green Tea Iced",
          rating: 4.96,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "24.67 SAR",
          category: "All Day Tea",
        },
        {
          name: "Green Tea with Milk Foam Hot",
          rating: 3.09,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "21.69 SAR",
          category: "All Day Tea",
        },
        {
          name: "Green Tea with Milk Foam Iced",
          rating: 3.25,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "16.67 SAR",
          category: "All Day Tea",
        },
        {
          name: "Oolong Tea Hot",
          rating: 3.29,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "21.61 SAR",
          category: "All Day Tea",
        },
        {
          name: "Oolong Tea Iced",
          rating: 4.74,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "9.28 SAR",
          category: "All Day Tea",
        },
        {
          name: "Oolong with Milk Foam Hot",
          rating: 3.34,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "8.68 SAR",
          category: "All Day Tea",
        },
        {
          name: "Oolong with Milk Foam Iced",
          rating: 4.86,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "29.87 SAR",
          category: "All Day Tea",
        },
      ],
      "Fruit Tea": [
        {
          name: "Chocolate Smoothie with Cookie Crumbs Iced",
          rating: 4.46,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "17.98 SAR",
          category: "Fruit Tea",
        },
        {
          name: "Lychee Oolong Tea with Aloe Iced",
          rating: 4.6,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "6.99 SAR",
          category: "Fruit Tea",
        },
        {
          name: "Mango Grapefruit Tea Frostino Iced",
          rating: 4.54,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "12.85 SAR",
          category: "Fruit Tea",
        },
        {
          name: "Mango Green Tea with Coconut Jelly Iced",
          rating: 3.5,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "17.23 SAR",
          category: "Fruit Tea",
        },
        {
          name: "Mango Latte with Milk Foam Iced",
          rating: 4.61,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "13.05 SAR",
          category: "Fruit Tea",
        },
        {
          name: "Mint Tea Frostino with cookie crumbs Iced",
          rating: 4.39,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "14.84 SAR",
          category: "Fruit Tea",
        },
        {
          name: "Strawberry Passion Fruit Tea with coconut jelly Iced",
          rating: 3.91,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "12.64 SAR",
          category: "Fruit Tea",
        },
      ],
      "Signature Tea": [
        {
          name: "Arabic Style Milk Tea with Coffee Jelly Iced",
          rating: 3.02,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "25.16 SAR",
          category: "Signature Tea",
        },
        {
          name: "Black Milk tea with Pearls Hot",
          rating: 3.26,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "11.37 SAR",
          category: "Signature Tea",
        },
        {
          name: "Black Milk tea with Pearls Iced",
          rating: 4.21,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "20.40 SAR",
          category: "Signature Tea",
        },
        {
          name: "Brown Sugar Milk Tea with Pearl Hot",
          rating: 3.63,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "14.21 SAR",
          category: "Signature Tea",
        },
        {
          name: "Brown Sugar Milk Tea with Pearl Iced",
          rating: 3.57,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "12.58 SAR",
          category: "Signature Tea",
        },
        {
          name: "Caramel Black Milk tea with Pearls Hot",
          rating: 4.12,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "22.59 SAR",
          category: "Signature Tea",
        },
        {
          name: "Caramel Milk tea with Pearls Iced",
          rating: 4.31,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "9.11 SAR",
          category: "Signature Tea",
        },
        {
          name: "Chocolate Milk with Milk Foam Cookie Crumbs Hot",
          rating: 4.19,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "25.75 SAR",
          category: "Signature Tea",
        },
        {
          name: "Chocolate Milk with Milk Foam Cookie Crumbs Iced",
          rating: 3.72,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "29.56 SAR",
          category: "Signature Tea",
        },
        {
          name: "Creme Brulee Brown Sugar Milk tea with Pearls Hot",
          rating: 3.88,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "22.32 SAR",
          category: "Signature Tea",
        },
        {
          name: "Creme Brulee Brown Sugar Milk tea with Pearls Iced",
          rating: 3.68,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "7.67 SAR",
          category: "Signature Tea",
        },
        {
          name: "Green tea with white Pearls Iced",
          rating: 4.8,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "8.70 SAR",
          category: "Signature Tea",
        },
        {
          name: "Matcha Milk Tea with Milk Foam Hot",
          rating: 4.3,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "11.87 SAR",
          category: "Signature Tea",
        },
        {
          name: "Matcha Milk Tea with Milk Foam Iced",
          rating: 4.14,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "17.53 SAR",
          category: "Signature Tea",
        },
        {
          name: "Mint Chocolate Green Milk Tea with pearls Crisp chocolate Iced",
          rating: 3.16,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "7.36 SAR",
          category: "Signature Tea",
        },
        {
          name: "Pistachio Milk Tea with Pearls Iced",
          rating: 4.75,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "15.65 SAR",
          category: "Signature Tea",
        },
        {
          name: "Strawberry Almond Jelly Milk Tea Iced",
          rating: 4.56,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "5.89 SAR",
          category: "Signature Tea",
        },
        {
          name: "Strawberry Green Milk tea wth strawberry Kanten Jelly Iced",
          rating: 4,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "13.39 SAR",
          category: "Signature Tea",
        },
        {
          name: "Taro Milk Tea with Milk Foam Hot",
          rating: 4.5,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro doloremque deserunt aperiam exercitationem repudiandae. Deleniti placeat, maiores magni est nobis veniam enim quae nemo dolores omnis saepe repudiandae soluta officiis.",
          picUrl: ".webp",
          price: "21.64 SAR",
          category: "Signature Tea",
        },
      ],
    },
  },
];
const loyaltyItems: LoyaltyItem[] = [
  {
    itemName: "Almond Kitkat Bar",
    itemPic: "/dunkin/Donuts/Almond Kitkat Bar.webp",
    itemPoints: 200,
    restaurant: restaurants[0],
  },
  {
    itemName: "Matcha Latte",
    itemPic: "/dunkin/Hot Beverages/Matcha Latte.webp",
    itemPoints: 150,
    restaurant: restaurants[0],
  },
  {
    itemName: "Smoked Beef Brisket",
    itemPic: "/arbys/Brisket/Smoked Beef Brisket.webp",
    itemPoints: 400,
    restaurant: restaurants[1],
  },
];
const trendingItems: TrendingItem[] = [
  {
    title: "Smash Burgers",
    description:
      "Crispy, caramelized edges with juicy centers, these burgers are all the rage in fast food.",
    picUrl: burger,
  },
  {
    title: "Stuffed Crust Pizza",
    description:
      "Cheese-filled crusts make every bite of this pizza a cheesy delight.",
    picUrl: trendingPizza,
  },
  {
    title: "Loaded Shawarma Wraps",
    description:
      "A Middle Eastern classic, now loaded with extra toppings like fries, garlic sauce, and pickles.",
    picUrl: tShawerma,
  },
  {
    title: "Chicken Sandwiches",
    description:
      "Crispy fried chicken sandwiched between soft buns, topped with pickles and mayo.",
    picUrl: tChicken,
  },
  {
    title: "BBQ Bacon Cheeseburgers",
    description:
      "Juicy beef patty topped with smoky BBQ sauce, crispy bacon, and melted cheese.",
    picUrl: tBbqBacon,
  },
  {
    title: "Buffalo Chicken Pizza",
    description:
      "Spicy buffalo sauce, grilled chicken, and a mix of cheeses make this pizza a hot favorite.",
    picUrl: tChickenPizza,
  },
  {
    title: "Falafel Shawarma",
    description:
      "A vegetarian twist on the classic shawarma, featuring crispy falafel and fresh veggies.",
    picUrl: tFalafel,
  },
  {
    title: "Pepperoni Pizza",
    description:
      "A timeless favorite, this pizza is topped with spicy pepperoni and loads of mozzarella cheese.",
    picUrl: tPepperoni,
  },
];

export default function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchRestaurants = async () => {
      dispatch(setRestaurants(restaurants));
      dispatch(setLoyaltyItems(loyaltyItems));
      dispatch(setTrendingItems(trendingItems));
    };
    fetchRestaurants();
  }, []);
  return (
    <Grid container sx={styles.containerGrid}>
      <Grid item xs={12}>
        <MainCarousel />
      </Grid>
      <Grid item xs={12} sx={styles.gridItem}>
        <Restaurants />
      </Grid>
      <Grid item xs={12} sx={styles.gridItem}>
        <LoyaltyProgram />
      </Grid>
      <Grid item xs={12}>
        {/* <TrendingNow /> */}
      </Grid>
    </Grid>
  );
}
const styles: Styles = {
  containerGrid: { mt: "95px" },
  gridItem: {
    px: "15px",
    py: "20px",
  },
};
