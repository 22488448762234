import { Box, Button, Divider, Link, Typography } from "@mui/material";
import { Styles } from "../../Interfaces";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/slices";
import { Link as RouterLink } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { setRegistrationModalOpen } from "../../store/slices/general";
import LoyaltyItemModal from "../../Components/Modals/LoyaltyItemModal";
import { useState } from "react";
export default function OneLoyaltyItem() {
  const [loyaltyItemModalOpen, setLoyaltyItemModalOpen] = useState(false);
  const selectedLoyaltyItem = useSelector(
    (state: StoreState) => state.loyaltyItems.selectedLoyaltyItem
  );
  const dispatch = useDispatch();
  const handleRedeem = () => {
    dispatch(setRegistrationModalOpen(true));
  };
  return (
    <Box sx={{ mt: "100px" }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: "70%",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                right: "-2px",
                background:
                  "rgb(254,254,254) linear-gradient(90deg, rgba(254,254,254,1) 0%, rgba(226,226,226,1) 100%)",
                padding: "3px 5px",
                top: "-2px",
                width: "40px",
                borderRadius: "0px 0px 0px 10px",
                // border: "1px solid white",
              }}
            >
              <Box
                component={"img"}
                src={selectedLoyaltyItem?.restaurant.picUrl}
                sx={{ width: "100%" }}
              />
            </Box>
            <Box
              component={"img"}
              src={selectedLoyaltyItem?.itemPic}
              sx={{ width: "100%" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "10px 0px",
            }}
          >
            <Box
              sx={{
                borderRadius: "30px",
                border: "2px solid #ef7724",
                padding: "2px 10px",
                mb: "5px",
                mt: "20px",
              }}
            >
              <Typography fontWeight={600} fontSize={14}>
                {selectedLoyaltyItem?.itemPoints} Points
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "10px 20px",
        }}
      >
        <Button
          variant="outlined"
          fullWidth
          sx={{ color: "#ef7724", borderColor: "#ef7724" }}
          onClick={() => setLoyaltyItemModalOpen(true)}
        >
          How It Works
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "10px 20px",
          gap: "10px",
        }}
      >
        <Typography fontSize={12}>
          These items are available for a limited time only. Take advantage of
          these wonderful deals before it is too late. Try it now!
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", paddingX: "20px" }}>
        <Divider sx={{ width: "100%", borderColor: "rgba(0,0,0,0.3)" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          padding: "10px 20px",
        }}
      >
        <Typography fontSize={16} fontWeight={700}>
          Rules
        </Typography>
        <Typography fontSize={12}>Login in to redeem</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", paddingX: "20px" }}>
        <Divider sx={{ width: "100%", borderColor: "rgba(0,0,0,0.3)" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          padding: "10px 20px",
        }}
      >
        <Link
          sx={{ color: "#22a1c2", fontSize: "12px" }}
          component={RouterLink}
          to={"/"}
        >
          Terms and Conditions
        </Link>
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: "0px",
          backgroundColor: "#e0e0e0 ",
          width: "100%",
          padding: "20px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          borderRadius: "30px 30px 0px 0px",
        }}
      >
        <Button
          fullWidth
          sx={{
            fontSize: "20px",
            textTransform: "none",
            backgroundColor: "#ef7724",
            boxShadow: "none",
            borderRadius: "10px",
            position: "relative", // Added to position the icons correctly within the button
            paddingRight: "40px", // Ensures there's space for t
            "&:hover": {
              bgcolor: "#ef7724",
              boxShadow: "unset",
            },
          }}
          onClick={handleRedeem}
          variant="contained"
        >
          Redeem Now
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "absolute",
              right: "10px",
            }}
          >
            <FaChevronRight
              size={16}
              style={{ position: "relative", left: "8px" }}
            />
            <FaChevronRight style={{ position: "relative", zIndex: 1 }} />
          </Box>
        </Button>
      </Box>
      <LoyaltyItemModal
        open={loyaltyItemModalOpen}
        onClose={() => setLoyaltyItemModalOpen(false)}
      />
    </Box>
  );
}
const styles: Styles = {
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: "20px",
  },
  link: {
    color: "#22a1c2",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "500",
  },
};
