import { Box, Skeleton, Typography } from "@mui/material";
import LoyaltyItem from "../../Interfaces/LoyaltyItem";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedLoyaltyItem } from "../../store/slices/loyaltyItems";
import { useState } from "react";

export default function LoyaltyItemContainer({
  itemName,
  itemPic,
  itemPoints,
  restaurant,
}: LoyaltyItem) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const handleNavigate = () => {
    dispatch(
      setSelectedLoyaltyItem({ itemName, itemPic, itemPoints, restaurant })
    );
    if (pathname === "/") {
      navigate("/loyaltyItems/" + itemName);
    } else {
      navigate(itemName);
    }
  };
  const [loaded, setLoaded] = useState(false);
  return (
    <Box
      sx={{
        mx: "10px",
        backgroundColor: "white",
        mb: "20px",
        borderRadius: "20px",
        overflow: "hidden",
        boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.75)",
      }}
      onClick={handleNavigate}
    >
      <Box sx={{ position: "relative", height: "135px" }}>
        <Box
          sx={{
            position: "absolute",
            right: "-2px",
            background:
              "rgb(254,254,254) linear-gradient(90deg, rgba(254,254,254,1) 0%, rgba(226,226,226,1) 100%)",
            padding: "3px 5px",
            top: "-2px",
            width: "40px",
            borderRadius: "0px 10px",
            // border: "1px solid white",
            minHeight: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component={"img"}
            src={restaurant.picUrl}
            sx={{ width: "100%" }}
          />
        </Box>
        {!loaded && (
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            animation="wave"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              // borderRadius: "15px",
            }}
          />
        )}
        <Box
          component={"img"}
          src={itemPic}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          onLoad={() => setLoaded(true)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "20px 0px",
          borderTop: "1px solid #ef7724",
        }}
      >
        <Box
          sx={{
            borderRadius: "30px",
            border: "2px solid #ef7724",
            padding: "2px 10px",
            mb: "5px",
          }}
        >
          <Typography fontWeight={600} fontSize={14}>
            {itemPoints} Points
          </Typography>
        </Box>
        <Box>
          <Typography fontSize={14}>{itemName}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
