import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/slices";
import { Styles } from "../../Interfaces";
import TrendingCard from "../../Components/TrendingNow/TrendingCard";

export default function Trending() {
  const trendingItems = useSelector(
    (state: StoreState) => state.trendingItems.trendingItems
  );
  return (
    <Grid container sx={{ mt: "120px", px: "20px" }}>
      <Grid container sx={styles.containerGrid}>
        {trendingItems.map((item) => (
          <Grid item xs={12} key={item.title} sx={styles.gridItem}>
            <TrendingCard
              description={item.description}
              picUrl={item.picUrl}
              title={item.title}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
const styles: Styles = {
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    color: "#22a1c2",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "500",
  },
};
