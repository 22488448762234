import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/slices";
import RestaurantIconContainer from "../../Components/Restaurants/RestaurantIconContainer";
import { Styles } from "../../Interfaces";
import LoyaltyItemContainer from "../../Components/Loyalty Program/LoyaltyItemContainer";

export default function Restaurant() {
  const loyaltyItems = useSelector(
    (state: StoreState) => state.loyaltyItems.loyaltyItems
  );
  return (
    <Grid container sx={{ mt: "120px", px: "20px" }}>
      <Grid container sx={styles.containerGrid}>
        {loyaltyItems.map((loyaltyItem) => (
          <Grid key={loyaltyItem.itemName} item xs={6} sx={styles.gridItem}>
            <LoyaltyItemContainer
              itemName={loyaltyItem.itemName}
              itemPic={loyaltyItem.itemPic}
              itemPoints={loyaltyItem.itemPoints}
              restaurant={loyaltyItem.restaurant}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
const styles: Styles = {
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    color: "#22a1c2",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "500",
  },
};
