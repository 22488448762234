import { AppBar, Toolbar, Typography, Box, Button } from "@mui/material";
import logoPath from "../../Assets/Shahia.png";
import { BiMenuAltLeft } from "react-icons/bi";
import { TbShoppingBagPlus } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  setMainDrawerOpen,
  setRegistrationModalOpen,
} from "../../store/slices/general";
import { Pages, Titles } from "../../constants/enums";
import { StoreState } from "../../store/slices";
export default function Navbar() {
  const { pathname } = useLocation();
  const slug = pathname.split("/");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goBack = () => {
    // const path = [...slug];
    // path.pop();
    // const newPath = path.join("/");
    // console.log(newPath);
    // navigate(newPath);
    navigate(-1);
  };
  const selectedRestaurant = useSelector(
    (state: StoreState) => state.restaurants.selectedRestaurant
  );
  const selectedLoyaltyItem = useSelector(
    (state: StoreState) => state.loyaltyItems.selectedLoyaltyItem
  );
  const renderTitle = (pageSlug: string) => {
    if (pageSlug === "/") {
      return <Box component={"img"} src={logoPath} width={"30%"} />;
    } else if (pageSlug === Pages.LoyaltyItemsPage) {
      return (
        <Typography sx={{ textTransform: "capitalize", fontWeight: "600" }}>
          {Titles.loyaltyItems}
        </Typography>
      );
    } else if (pageSlug === Pages.RestaurantsPage) {
      return (
        <Typography sx={{ textTransform: "capitalize", fontWeight: "600" }}>
          {Titles.restaurants}
        </Typography>
      );
    } else if (pageSlug === Pages.TrendingItemsPage) {
      return (
        <Typography sx={{ textTransform: "capitalize", fontWeight: "600" }}>
          {Titles.Trending}
        </Typography>
      );
    } else if (pageSlug.includes(Pages.oneRestaurant)) {
      console.log(selectedRestaurant);
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component={"img"}
            src={selectedRestaurant?.picUrl}
            sx={{ maxHeight: "50px", flexGrow: "0", maxWidth: "60%" }}
          />
          <Typography
            color={"#ef7724"}
            fontSize={12}
            marginTop={1}
            fontWeight={300}
          >
            Business Hours: 10:00 AM - 11:00 PM{" "}
          </Typography>
        </Box>
      );
    } else if (pageSlug.includes(Pages.oneLoyaltyItem)) {
      console.log(selectedLoyaltyItem);
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></Box>
      );
    } else {
      return <Box component={"img"} src={logoPath} width={"30%"} />;
    }
  };

  const handlePress = () => {
    console.log("Pressed");
    if (slug[1]) {
      goBack();
    } else {
      dispatch(setMainDrawerOpen(true));
    }
  };
  const handleOpenRegistrationModal = () => {
    dispatch(setRegistrationModalOpen(true));
  };
  return (
    <Box sx={{ position: "relative", zIndex: 9999 }}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#f2f5fa",
          // borderRadius: "0 0 0 70px",
          padding: "20px",
          // boxShadow: "none",
          // height: "100px",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          {/* Left Icon */}
          <Button
            variant="contained"
            aria-label="menu"
            sx={{
              // width: "35px",
              minWidth: "0px",
              backgroundColor: "#ef7724",
              boxShadow: "unset",
              p: "8px",
              borderRadius: "15px",
              "&:hover": { bgcolor: "#ef7724", boxShadow: "none" },
            }}
            onClick={handlePress}
          >
            {slug[1] ? (
              <IoMdArrowRoundBack size={"26px"} />
            ) : (
              <BiMenuAltLeft size={"26px"} />
            )}
          </Button>
          {renderTitle(pathname)}
          {/* Right Icon */}
          <Button
            variant="contained"
            aria-label="menu"
            sx={{
              // width: "35px",
              minWidth: "0px",
              backgroundColor: "#ef7724",
              boxShadow: "unset",
              p: "8px",
              borderRadius: "15px",
              position: "relative",
              "&:hover": { bgcolor: "#ef7724", boxShadow: "none" },
            }}
            onClick={handleOpenRegistrationModal}
          >
            <Box
              sx={{
                position: "absolute",
                backgroundColor: "#D64545",
                borderRadius: "50%",
                height: "10px",
                width: "10px",
                top: "7px",
                left: "7px",
              }}
            ></Box>
            <TbShoppingBagPlus size={"26px"} />
          </Button>
        </Toolbar>
        {/* <Box
          sx={{
            position: "absolute",
            bottom: "-20px",
            // left: "0px",
            right: "0px",
            width: "40px",
            height: "40px",
            backgroundColor: "#ef7724",
            "&::before": {
              content: '""',
              position: "absolute",
              bottom: "0",
              left: "0",
              width: "40px",
              height: "40px",
              backgroundColor: "#22a1c2",
              borderBottomLeftRadius: "40px",
              boxShadow: "0 20px 0 0 #ef7724",
            },
          }}
        ></Box> */}
      </AppBar>
    </Box>
  );
}
