import { Box, Typography } from "@mui/material";
import RestaurantMenuItem from "./RestaurantMenuItem";
import { MenuItem } from "../../Interfaces/Restaurant";
import { useState, useEffect, useRef } from "react";

export default function RestaurantMenu({
  menuItems,
  selectedRestaurantName,
}: {
  menuItems: MenuItem[];
  selectedRestaurantName: string;
}) {
  const [itemsToShow, setItemsToShow] = useState(6);
  const itemHeight = 180; // Height of each item
  const containerRef = useRef<HTMLDivElement>(null);

  const loadMoreItems = (e: React.UIEvent) => {
    const target = e.target as HTMLDivElement;
    const scrollPosition = target.scrollTop;
    const containerHeight = target.clientHeight;
    const totalScrollHeight = target.scrollHeight;

    // Check if user is near the bottom
    if (
      totalScrollHeight - (scrollPosition + containerHeight) <=
      itemHeight * 3
    ) {
      setItemsToShow((prev) => Math.min(prev + 6, menuItems.length));
    }
  };

  // Scroll to the top when menuItems change
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
    setItemsToShow(6); // Reset to show initial 10 items
  }, [menuItems]);

  return (
    <Box
      ref={containerRef}
      onScroll={loadMoreItems}
      sx={{ height: "100%", overflowY: "auto" }}
    >
      {menuItems && menuItems.length > 0 ? (
        menuItems
          .slice(0, itemsToShow)
          .map((item) => (
            <RestaurantMenuItem
              key={`${selectedRestaurantName}-${item.name}`}
              name={item.name}
              price={item.price}
              rating={item.rating}
              description={item.description}
              picUrl={`/${selectedRestaurantName.toLowerCase()}/${
                item.category
              }/${item.name}${item.picUrl}`}
            />
          ))
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            height: "100%",
          }}
        >
          <Typography
            fontWeight={700}
            fontSize={24}
            sx={{ width: "70%", color: "#ef7724", textAlign: "center" }}
          >
            No Items Listed Yet.
          </Typography>
        </Box>
      )}
    </Box>
  );
}
