import { Box, Button, Modal, Typography, Fade } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/slices";
import { setRegistrationModalOpen } from "../../store/slices/general";
import regModalPic from "../../Assets/registrationModal.png";
import { IoIosCloseCircleOutline } from "react-icons/io";
export default function LoyaltyItemModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const handleClose = () => {
    // dispatch(setRegistrationModalOpen(false));
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box
            sx={{ position: "absolute", right: "20px", top: "10px" }}
            onClick={handleClose}
          >
            <IoIosCloseCircleOutline size={20} />
          </Box>
          <Typography
            id="modal-modal-description"
            textAlign={"center"}
            fontWeight={700}
            fontSize={14}
            marginBottom={1}
          >
            How it works
          </Typography>
          <Typography
            id="modal-modal-description"
            textAlign={"left"}
            fontWeight={500}
            fontSize={10}
          >
            These items are available for a limited time only. Take advantage of
            these wonderful deals before it is too late. Try it now!
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "white",
  p: 4,
  minWidth: "300px",
  borderRadius: "20px",
};
