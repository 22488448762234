import { Box, Grid, Link, SxProps, Typography } from "@mui/material";
import { Link as ReactRouterLink } from "react-router-dom";
import { Styles } from "../../Interfaces";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/slices";
import RestaurantIconContainer from "../Restaurants/RestaurantIconContainer";
import LoyaltyItemContainer from "./LoyaltyItemContainer";

export default function LoyaltyProgram() {
  const loyaltyItems = useSelector(
    (state: StoreState) => state.loyaltyItems.loyaltyItems
  );
  return (
    <>
      <Box sx={styles.titleContainer}>
        <Typography fontFamily={"Rubik"} fontWeight={"600"} fontSize={18}>
          Loyalty Program
        </Typography>
        {/* <Link to={"/loyaltyItems"} component={ReactRouterLink} sx={styles.link}>
          See More
          <ChevronRightIcon />
        </Link> */}
      </Box>
      <Grid container sx={styles.containerGrid}>
        {loyaltyItems.map((loyaltyItem) => (
          <Grid key={loyaltyItem.itemName} item xs={6} sx={styles.gridItem}>
            <LoyaltyItemContainer
              itemName={loyaltyItem.itemName}
              itemPic={loyaltyItem.itemPic}
              itemPoints={loyaltyItem.itemPoints}
              restaurant={loyaltyItem.restaurant}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
const styles: Styles = {
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    color: "#22a1c2",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "500",
  },
  containerGrid: { mt: "20px" },
  gridItem: {},
};
