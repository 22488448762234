import { Box, Typography } from "@mui/material";
import React from "react";
import TrendingItem from "../../Interfaces/TrendingItem";
import { IoMdArrowRoundForward } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setSelectedTrendingItem } from "../../store/slices/trendingItems";

export default function TrendingCard({
  title,
  picUrl,
  description,
}: TrendingItem) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleNavigate = () => {
    dispatch(setSelectedTrendingItem({ title, picUrl, description }));
    if (pathname === "/") {
      navigate("/trending/" + title);
    } else {
      navigate(title);
    }
  };
  return (
    <Box
      sx={{
        padding: "5px",
        border: "1px solid #22a1c2",
        borderRadius: "15px",
        my: "5px",
        position: "relative",
        display: "flex",
        gap: "10px",
      }}
      onClick={handleNavigate}
    >
      <Box
        sx={{
          width: "40%",
          height: "160px",
          overflow: "hidden",
          borderRadius: "15px",
          flexShrink: "0",
        }}
      >
        <Box
          component={"img"}
          src={picUrl}
          sx={{
            objectFit: "cover",
            objectPosition: "center",
            width: "100%",
            height: "100%",
          }}
        />
      </Box>
      <Box sx={{ width: "50%" }}>
        <Typography fontSize={14} fontWeight={700}>
          {title}
        </Typography>
        <Typography fontSize={14} fontWeight={400}>
          {description}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          backgroundColor: "#d8d8d7",
          borderRadius: "50%",
          width: "25px",
          height: "25px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IoMdArrowRoundForward size={"20px"} />
      </Box>
    </Box>
  );
}
