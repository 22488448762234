import { Box, Button, Modal, Typography, Fade } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/slices";
import { setRegistrationModalOpen } from "../../store/slices/general";
import regModalPic from "../../Assets/registrationModal.png";

export default function RegistrationModal() {
  const open = useSelector(
    (state: StoreState) => state.general.registrationModalOpen
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setRegistrationModalOpen(false));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box component={"img"} src={regModalPic} />
          <Typography
            id="modal-modal-description"
            textAlign={"center"}
            fontWeight={700}
          >
            Join Shahia Family To Enjoy Our Services
          </Typography>
          <Box
            sx={{
              display: "flex",
              mt: "20px",
              justifyContent: "center",
              textTransform: "none",
            }}
          >
            <Button
              sx={{
                mx: "auto",
                bgcolor: "#ef7724",
                textTransform: "none",
                "&:hover": { bgcolor: "#ef7724" },
              }}
              variant="contained"
            >
              Register Now
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "white",
  p: 4,
  minWidth: "300px",
  borderRadius: "20px",
};
