import { Box, Grid, Typography, Button } from "@mui/material";
import { FaExclamationTriangle } from "react-icons/fa"; // Importing an icon from react-icons
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router

export default function NotFound() {
  const navigate = useNavigate(); // To navigate back to home or previous page

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ px: "20px", textAlign: "center", height: "100vh" }}
    >
      <Box>
        <FaExclamationTriangle size={80} color="#ef7724" />
      </Box>
      <Typography variant="h3" sx={{ mt: "20px", fontWeight: "bold" }}>
        404 - Page Not Found
      </Typography>
      <Typography variant="body1" sx={{ mt: "10px", color: "#757575" }}>
        The page you are looking for might have been removed or is temporarily
        unavailable.
      </Typography>
      <Button
        variant="outlined"
        sx={{
          mt: "30px",
          px: "30px",
          py: "10px",
          borderColor: "#ef7724",
          color: "#ef7724",
        }}
        onClick={() => navigate("/")}
      >
        Go to Homepage
      </Button>
    </Grid>
  );
}
